import axios from "axios";
import { store } from "../../index";

export const saveProfilePhoto = async (profilePicUrl) => {
  const state = store.getState();
  const userDetails =
    /*LocalStorageGetValue("userDetails")*/ state?.providerDetailsReducer
      ?.provider;
  const providerId = /*JSON.parse(userDetails)?.id*/ userDetails?.id;
  const providerToken =
    /*LocalStorageGetValue("token")*/ state?.providerDetailsReducer?.token;

  const params = {
    id: providerId,
    profilePicUrl,
    token: providerToken,
  };
  try {
    return await axios.post(`api/providerEndpoints/v1/updateProvider`, params);
  } catch (error) {
    console.log(error);
  }
};
