import * as React from "react";
import { render } from "react-dom";
import {
  Button,
  Dialog,
  DialogActions,
  Link,
  useMediaQuery,
} from "@material-ui/core";
import "../../Styles/Typography/CustomTypography.scss";
import CloseIcon from "@mui/icons-material/Close";
import phonemodel from "../../Assets/images/phone.svg";
import qrcodeAppleStore from "../../Assets/images/OmnicureNowAppStore.jpg";
import qrcodePlayStore from "../../Assets/images/OmnicureNowPlayStore.jpg";
import logoAppleStore from "../../Assets/images/appleStore.svg";
import logoPlayStore from "../../Assets/images/playStore.svg";
import { withTranslation } from "react-i18next";
import { detect } from "detect-browser";
import { cond, always, T } from "ramda";
import { toggleUnsupportedBrowser } from "../../Redux/Actions/unsupportedbrowseraction";
import { useDispatch } from "react-redux";
const browserCond = cond([
  [(name) => name.match(/chrome|chromium|crios/i), always(98)],
  [(name) => name.match(/firefox|fxios/i), always(98)],
  [(name) => name.match(/edg/i), always(100)],
  [(name) => name.match(/safari/i), always(14)],
  [T, always("unsupported browser")],
]);

const { name: browserName, os, version } = detect();

function UnsupportedBrowserBanner() {
  return (
    <div
      style={{
        backgroundColor: "#5E6C84",
        width: "100%",
        color: "#B3BAC5",
        fontSize: 14,
        lineHeight: "24px",
        fontFamily: "PoppinsRegular",
        textAlign: "center",
      }}
    >
      For the best experience please use a supported browser{" "}
      <Link
        style={{ color: "#FFF" }}
        target="_blank"
        href="https://omnicurenow.com/browsers"
      >
        See All
      </Link>
    </div>
  );
}

function LoginDialog(props) {
  const fitToScreen = useMediaQuery("(min-width:600px) and (min-height:400px)");
  const { t } = props;
  const [openBrowser, setopenBrowser] = React.useState(false);
  const [openMobile, setopenMobile] = React.useState(false);
  const [qrCode, setqrCode] = React.useState("playstore");
  const dispatch = useDispatch();
  const handleClickOpenBrowser = (reason) => {
    if (reason === "backdropClick") {
      return;
    }
    setopenBrowser(!openBrowser);
  };
  const handleClickOpenMobile = (reason) => {
    if (reason === "backdropClick") {
      return;
    }
    setopenMobile(!openMobile);
  };

  React.useEffect(() => {
    const browserVersion = browserCond(browserName);
    const currentBrowserVersion = Number(version.split(".")[0]);
    if (os.match(/Windows/)) {
      if (
        browserVersion === "unsupported browser" ||
        browserVersion > currentBrowserVersion
      ) {
        handleClickOpenBrowser();
        dispatch(toggleUnsupportedBrowser());
        render(
          <UnsupportedBrowserBanner />,
          document.getElementById("unsupportedbrowser")
        );
      }
    } else if (os.match(/Mac/)) {
      if (navigator.maxTouchPoints > 1) {
        handleClickOpenMobile();
        setqrCode();
        dispatch(toggleUnsupportedBrowser());
        render(
          <UnsupportedBrowserBanner />,
          document.getElementById("unsupportedbrowser")
        );
      } else if (
        browserVersion === "unsupported browser" ||
        browserVersion > currentBrowserVersion
      ) {
        handleClickOpenBrowser();
        dispatch(toggleUnsupportedBrowser());
        render(
          <UnsupportedBrowserBanner />,
          document.getElementById("unsupportedbrowser")
        );
      }
    } else if (os.match(/iOS|Android/)) {
      dispatch(toggleUnsupportedBrowser());
      render(
        <UnsupportedBrowserBanner />,
        document.getElementById("unsupportedbrowser")
      );
      handleClickOpenMobile();
      if (os.match(/iOS/)) {
        setqrCode();
      } else if (os.match(/Android/)) {
        setqrCode("playstore");
      }
    }
  }, []);

  const iosDeviceCheck = os.match(/iOS/);

  return (
    <div>
      {/* <div style={{ marginTop: 20, marginLeft: 25, background: "none" }}>
        <Button onClick={() => handleClickOpenBrowser()}></Button>
        <Button onClick={() => handleClickOpenMobile()}></Button>
      </div> */}
      {/* Continue Dialog */}
      <Dialog
        open={openBrowser && fitToScreen}
        maxWidth="md"
        onClose={(e, reason) => handleClickOpenBrowser(reason)}
        aria-labelledby="incompatible-browser"
        id="incompatible-browser"
        PaperProps={{
          style: {
            borderRadius: 10,
          },
        }}
      >
        <div style={{ width: "600px", height: "300px" }}>
          <div className="incompatibleHeader incompatibleHeader2">
            {t("popup.incompatible")}
            <CloseIcon
              onClick={() => handleClickOpenBrowser()}
              fontSize="small"
              className="incompatibleCloseIcon"
            />
          </div>
          <div className="incompatibleHr"></div>
          <div className="incompatibleTitle" style={{ fontSize: 18 }}>
            {t("popup.incompatibleTitle")}
          </div>
          <div className="incompatibleContent">
            {t("popup.incompatibleContent")}
          </div>
          <div className="incompatibleContent">
            {t("popup.incompatibleContentChild")}{" "}
            <a
              target="_blank"
              href="https://omnicurenow.com/browsers"
              style={{ color: "#9b51e0" }}
              rel="noreferrer"
            >
              {"supported browsers"}
            </a>
          </div>

          <DialogActions>
            <Button
              variant="contained"
              color="default"
              arial-label="override yes"
              id="overrideyes"
              className="incompatibleButton"
              onClick={handleClickOpenBrowser}
            >
              {t("poup.incompatilblebutton")}
            </Button>
          </DialogActions>
        </div>
      </Dialog>
      <Dialog
        open={openBrowser && !fitToScreen}
        maxWidth="md"
        onClose={(e, reason) => handleClickOpenBrowser(reason)}
        aria-labelledby="incompatible-browser"
        id="incompatible-browser"
        PaperProps={{
          style: {
            borderRadius: 10,
          },
        }}
      >
        <div style={{ width: "min(80vw,600px)" }}>
          <div className="incompatibleHeader incompatibleHeader2">
            {t("popup.incompatible")}
            <CloseIcon
              onClick={() => handleClickOpenBrowser()}
              fontSize="small"
              className="incompatibleCloseIcon"
            />
          </div>
          <div className="incompatibleHr"></div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              margin: 28,
              rowGap: 12,
            }}
          >
            <div className="incompatibleTitle" style={{ fontSize: 18 }}>
              {t("popup.incompatibleTitle")}
            </div>
            <div className="incompatibleContent">
              {t("popup.incompatibleContent")}
            </div>
            <div className="incompatibleContent">
              {t("popup.incompatibleContentChild")}{" "}
              <a
                target="_blank"
                href="https://omnicurenow.com/browsers"
                style={{ color: "#9b51e0" }}
                rel="noreferrer"
              >
                {"supported browsers"}
              </a>
            </div>

            <DialogActions>
              <Button
                variant="contained"
                color="default"
                arial-label="override yes"
                id="overrideyes"
                className="incompatibleButton"
                onClick={handleClickOpenBrowser}
              >
                {t("poup.incompatilblebutton")}
              </Button>
            </DialogActions>
          </div>
        </div>
      </Dialog>
      {/* download dialog */}
      <Dialog
        open={openMobile && fitToScreen}
        maxWidth="md"
        onClose={(e, reason) => handleClickOpenMobile(reason)}
        aria-labelledby="incompatible-mobile"
        id="incompatible-mobile"
        PaperProps={{
          style: {
            borderRadius: 10,
          },
        }}
      >
        <div style={{ width: "600px", minHeight: "300px" }}>
          <div className="incompatibleHeader">
            {t("popup.mobile")}
            <CloseIcon
              onClick={() => handleClickOpenMobile()}
              fontSize="small"
              className="incompatibleCloseIcon"
            />
          </div>
          <div className="incompatibleHr"></div>

          <div class="gridContainer" style={{ paddingLeft: 50 }}>
            <div class="item1">
              {/* <img
                src={iPhoneProScreen}
                style={{
                  position: "absolute",
                  transform: "translate(5px,4px)",
                }}
              ></img> */}
              <img src={phonemodel} />
            </div>
            <div class="item2" style={{ marginLeft: 35 }}>
              <div className="mobileTitle" style={{ textAlign: "left" }}>
                {t("popup.mobileTitle")}
              </div>
              <div className="mobileContent" style={{ paddingLeft: 0 }}>
                {t("popup.mobileContent")}
              </div>
            </div>
            <div class="item3" style={{ marginLeft: 35 }}>
              <div
                class="gridContainerChid"
                style={{
                  display: "flex",
                  alignItems: "end",
                  ...(!iosDeviceCheck && { columnGap: 31 }),
                  "-webkit-align-items": "flex-end",
                  "-webkit-column-gap": "31px",
                }}
              >
                {qrCode === "playstore" ? (
                  <div class="qrcodeitem" style={{ paddingLeft: 0 }}>
                    <img
                      className="qrcode"
                      src={qrcodePlayStore}
                      style={{ boxShadow: "3px 3px 6px 2px #dadade" }}
                    ></img>
                  </div>
                ) : (
                  <div class="qrcodeitem" style={{ paddingLeft: 0 }}>
                    <img
                      className="qrcode"
                      src={qrcodeAppleStore}
                      style={{ boxShadow: "3px 3px 6px 2px #dadade" }}
                    ></img>
                  </div>
                )}

                {qrCode !== "playstore" ? (
                  <div
                    class="applelogoitem"
                    style={{ ...(iosDeviceCheck && { paddingLeft: 31 }) }}
                  >
                    <a
                      target="_blank"
                      href="https://apps.apple.com/us/app/omnicure-now/id1515928477"
                      rel="noreferrer"
                    >
                      <img className="logoStore" src={logoAppleStore}></img>
                    </a>
                  </div>
                ) : (
                  <div class="playlogoitem">
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.mvp.omnicure"
                      rel="noreferrer"
                    >
                      <img className="logoStore" src={logoPlayStore}></img>
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Dialog>
      <Dialog
        open={openMobile && !fitToScreen}
        maxWidth="md"
        onClose={(e, reason) => handleClickOpenMobile(reason)}
        aria-labelledby="incompatible-mobile"
        id="incompatible-mobile"
        PaperProps={{
          style: {
            borderRadius: 10,
          },
        }}
      >
        <div style={{ width: "min(80vw,600px)" }}>
          <div className="incompatibleHeader">
            {t("popup.mobile")}
            <CloseIcon
              onClick={() => handleClickOpenMobile()}
              fontSize="small"
              className="incompatibleCloseIcon"
            />
          </div>
          <div className="incompatibleHr"></div>

          <div
            style={{
              display: "grid",
              gridAutoFlow: "row",
              rowGap: 28,
              padding: "5px 20px 45px",
            }}
          >
            {/* <div class="item1">
              <img src={phonemodel} />
            </div> */}
            <div>
              <div
                className="mobileTitle"
                style={{
                  textAlign: "center",
                  lineHeight: "21px",
                  fontFamily: "PoppinsBold",
                }}
              >
                {t("popup.mobileTitle")}
              </div>
              <div
                className="mobileContent"
                style={{
                  textAlign: "center",
                  lineHeight: "18px",
                  paddingLeft: 0,
                }}
              >
                {t("popup.mobileContent")}
              </div>
            </div>
            <div>
              <div
                class="gridContainerChid"
                style={{
                  display: "flex",
                  alignItems: "end",
                  ...(!iosDeviceCheck && { columnGap: 31 }),
                  justifyContent: "center",
                  "-webkit-align-items": "flex-end",
                }}
              >
                {qrCode === "playstore" ? (
                  <div class="qrcodeitem" style={{ paddingLeft: 0 }}>
                    <img
                      className="qrcode"
                      src={qrcodePlayStore}
                      style={{ boxShadow: "3px 3px 6px 2px #dadade" }}
                    ></img>
                  </div>
                ) : (
                  <div class="qrcodeitem" style={{ paddingLeft: 0 }}>
                    <img
                      className="qrcode"
                      src={qrcodeAppleStore}
                      style={{ boxShadow: "3px 3px 6px 2px #dadade" }}
                    ></img>
                  </div>
                )}

                {qrCode !== "playstore" ? (
                  <div
                    class="applelogoitem"
                    style={{ ...(iosDeviceCheck && { paddingLeft: 31 }) }}
                  >
                    <a
                      target="_blank"
                      href="https://apps.apple.com/us/app/omnicure-now/id1515928477"
                      rel="noreferrer"
                    >
                      <img className="logoStore" src={logoAppleStore}></img>
                    </a>
                  </div>
                ) : (
                  <div class="playlogoitem">
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.mvp.omnicure"
                      rel="noreferrer"
                    >
                      <img className="logoStore" src={logoPlayStore}></img>
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
export default withTranslation()(LoginDialog);
