import axios from "axios";
import { toast } from "react-toastify";
import { store } from "../index";
import { AES256_GCM_decrypt } from "./Auth/encyptionDecryption";

export const getConsultDetail = async (patientId) => {
  try {
    const response = await axios.post(
      `api/patientEndpoints/v1/getPatientDetailsById`,
      {
        patientId,
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const updateConsultCompletionSummary = async (payload) => {
  try {
    const response = await axios.post(
      `api/patientEndpoints/v1/updateConsultCompletionSummary`,
      payload
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const addCompleteConsultTime = async (data) => {
  try {
    const response = await axios.post(
      `api/patientEndpoints/v1/updateConsultCompletionTime`,
      data
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getReConsultDetail = async (id, patientId) => {
  try {
    const response = await axios.post(
      `api/patientEndpoints/v1/getConsultDetailsById`,
      {
        id,
        patientId,
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const updateAcuities = async (id, token, patientId, score) => {
  try {
    const response = await axios.post(
      `api/patientEndpoints/v1/resetAcuityScore`,
      {
        id,
        token,
        patientId,
        score,
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getTeamDetails = async (patientId, teamName) => {
  try {
    let team = `Team ${teamName}`;
    const response = await axios.post(
      `api/providerEndpoints/v1/teamDetailsByName`,
      {
        patientId,
        teamName: team,
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getPatientHistory = async (id, patientId) => {
  const state = store.getState();
  const token =
    /*LocalStorageGetValue("token")*/ state?.providerDetailsReducer?.token;
  try {
    const response = await axios.post(
      `api/patientEndpoints/v1/getPatientHistory`,
      {
        id: id,
        patientId: patientId,
        token: token,
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getTeams = async (providerId) => {
  try {
    const response = await axios.post(`api/providerEndpoints/v1/teamDetails`, {
      providerId,
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const acceptEconsult = async (id, token, patientId, setLoaderFlag) => {
  try {
    let obj = {
      id: id,
      token: token,
      patientId: patientId,
    };
    const response = await axios.post(
      `api/patientEndpoints/v1/acceptInvite`,
      obj
    );
    if (setLoaderFlag) {
      setLoaderFlag(false);
    }
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
export const acceptHandoffEconsult = async (
  id,
  token,
  patientId,
  setLoaderFlag
) => {
  try {
    let obj = {
      providerId: id,
      patientId: patientId,
    };
    const response = await axios.post(
      `api/providerEndpoints/v1/doRDHandOff`,
      obj
    );
    if (setLoaderFlag) {
      setLoaderFlag(false);
    }
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
export const decrypt = async (message = "") => {
  const CryptoJS = require("crypto-js");
  const key =
    "E78F5DF838969053C89DB80649AD3A200C93F0108CA2ED7C04499A956FEFD63A";
  const hexStr = message;
  const hexIV = hexStr.substring(0, 32);
  const cipherHexStr = hexStr.substring(128);
  const parsedKey = CryptoJS.enc.Hex.parse(key.toLowerCase());
  const parsedHexIV = CryptoJS.enc.Hex.parse(hexIV);
  const parsedcipherHexStr = CryptoJS.enc.Hex.parse(cipherHexStr);
  const decrypted = CryptoJS.AES.decrypt(
    { ciphertext: parsedcipherHexStr },
    parsedKey,
    {
      iv: parsedHexIV,
      mode: CryptoJS.mode.CBC,
    }
  );
  return decrypted.toString(CryptoJS.enc.Utf8);
};

export async function GetProvidersList(hospitalId, providerId) {
  try {
    const { data } = await axios.post(
      "api/patientEndpoints/v1/GetProvidersForHospital",
      { hospitalId, providerId }
    );
    return data;
  } catch (error) {
    console.log(error);
  }
}
export async function GetWardsWithinHospital(hospitalId) {
  try {
    const { data } = await axios.post(
      "api/hospitalEndpoints/v1/getWardsByHospital",
      { hospitalId }
    );
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function GetTransferHospitalList(patientId) {
  try {
    const { data } = await axios.post(
      "api/patientEndpoints/v1/GetTransferHospitalList",
      { patientId }
    );
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function BSPHandOffList(providerId) {
  try {
    const { data } = await axios.post(
      "api/providerEndpoints/v1/BspHandOffList",
      { providerId }
    );
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function BPHandOver(bspProviderId, summaryNote) {
  try {
    const { data } = await axios.post(
      "api/providerEndpoints/v1/DoBspHandover",
      { bspProviderId, summaryNote }
    );
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function RPHandOver(remoteProviderId, summaryNote, patientId) {
  try {
    const { data } = await axios.post(
      "api/providerEndpoints/v1/RDHandOffRequest",
      { remoteProviderId, summaryNote, patientId }
    );
    if (data.status) {
      toast.success(data.errorMessage);
    } else {
      toast.error(data.errorMessage);
    }
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function doTransferWithinHospital(requestPayload) {
  try {
    const state = store.getState();
    const { id } =
      /*JSON.parse(LocalStorageGetValue("userDetails"))*/ state
        ?.providerDetailsReducer?.provider;
    const { data } = await axios.post(
      "api/patientEndpoints/v1/doTransferWithinHospital",
      { ...requestPayload, bspProviderId: id }
    );
    if (data.status) {
      toast.success(data.errorMessage);
    } else {
      toast.error(data.errorMessage);
    }
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function patientDischarge(requestPayload) {
  try {
    const res = await axios.post(
      "api/patientEndpoints/v1/dischargePatient",
      requestPayload
    );
    if (res.data.status) {
      toast.success(res.data.errorMessage);
    } else {
      toast.error(res.data.errorMessage);
    }
    return res;
  } catch (error) {
    console.log(error);
  }
}

export async function saveDraft(requestPayload) {
  try {
    const { data } = await axios.post(
      "api/providerEndpoints/v1/saveDraftMessage",
      requestPayload
    );

    return data;
  } catch (error) {
    console.log(error);
  }
}
export async function getDraft(requestPayload) {
  try {
    const { data } = await axios.post(
      "api/providerEndpoints/v1/getDraftMessages",
      requestPayload
    );
    return data.chatMessages;
  } catch (error) {}
}

export async function decrypteConsult(requestPayload) {
  try {
    let message;
    await AES256_GCM_decrypt({ data: { encryptedValue: requestPayload } }).then(
      (decryptedValue) => {
        if (typeof decryptedValue === "string" && decryptedValue) {
          message = decryptedValue;
        }
      }
    );
    return message;
  } catch (error) {}
}

export const reconsultPatient = async (patientId) => {
  try {
    const state = store.getState();
    const id =
      /*JSON.parse(LocalStorageGetValue("id"))*/ state?.providerDetailsReducer
        ?.provider?.id;
    const token =
      /*LocalStorageGetValue("token")*/ state?.providerDetailsReducer?.token;
    const response = await axios.post(
      `api/patientEndpoints/v1/inviteProviderBroadCast`,
      {
        patientId,
        id,
        token,
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const reconsultOtherPatient = async (patientId, bspProviderId) => {
  try {
    const state = store.getState();
    const id =
      /*JSON.parse(LocalStorageGetValue("id"))*/ state?.providerDetailsReducer
        ?.provider?.id;
    const token =
      /*LocalStorageGetValue("token")*/ state?.providerDetailsReducer?.token;
    const response = await axios.post(
      `api/providerEndpoints/v1/doReconsultOtherPatient`,
      {
        patientId,
        otherBspProviderId: id,
        bspProviderId,
        token,
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
export async function DischargedPatient(requestPayload, patientId) {
  try {
    let obj = {
      patientId: patientId,
      dischargeSummary: requestPayload,
    };
    const { data } = await axios.post(
      "api/patientEndpoints/v1/doPatientDischarge",
      obj
    );
    if (!data.status) {
      toast.error(data.errorMessage);
    }
    return data;
  } catch (error) {
    console.log(error);
  }
}
