import axios from "axios";
import { toast } from "react-toastify";
import { refreshTokenUpdates } from "../HealthMonitor";
import { LocalStorageClear } from "../../Services/Auth";
import { store } from "../../index";

export const getProviderType = async () => {
  try {
    const response = await axios.get(
      `api/providerEndpoints/v1/GetRemoteProviderType`
    );
    return response.data.remoteProviderTypeList;
  } catch (error) {
    errorTasks(error);
  }
};

export const hospitalLists = async () => {
  const state = store.getState();
  const userDetails =
    /*LocalStorageGetValue("userDetails")*/ state?.providerDetailsReducer
      ?.provider;
  const providerId = /*JSON.parse(userDetails)?.id*/ userDetails?.id;
  try {
    const response = await axios.post(`api/hospitalEndpoints/v1/getHospitals`, {
      id: providerId,
    });
    return response.data.hospitalList;
  } catch (error) {
    errorTasks(error);
  }
};

export const listProviders = async (role) => {
  const state = store.getState();
  const userDetails =
    /*LocalStorageGetValue("userDetails")*/ state?.providerDetailsReducer
      ?.provider;
  const providerId = /*JSON.parse(userDetails)?.id*/ userDetails?.id;
  const providerToken = /*JSON.parse(userDetails)?.token*/ userDetails?.token;
  try {
    const response = await axios.post(
      `api/providerEndpoints/v1/getRemoteProviderRemoteDirectory`,
      {
        providerId: providerId,
        token: providerToken,
        role,
      }
    );
    return response.data.providerList;
  } catch (error) {
    errorTasks(error);
  }
};

export const listOnlineProviders = async (role) => {
  const state = store.getState();
  const userDetails =
    /*LocalStorageGetValue("userDetails")*/ state?.providerDetailsReducer
      ?.provider;
  const providerId = /*JSON.parse(userDetails)?.id*/ userDetails?.id;
  const providerToken = /*JSON.parse(userDetails)?.token*/ userDetails?.token;
  try {
    const response = await axios.post(
      `api/providerEndpoints/v1/getRemoteProviderOnlineProviders`,
      {
        providerId: providerId,
        token: providerToken,
        role,
      }
    );
    return response.data.providerList;
  } catch (error) {
    errorTasks(error);
  }
};

function errorTasks(error) {
  if ([401, 504, 500, 704].includes(error.response.status)) {
    LocalStorageClear();
    sessionStorage.removeItem("currentPath");
    window.location.replace(window.location.href);
  }
  if ([703, 502, 404].includes(error.response.status)) {
    refreshTokenUpdates(null);
    toast.warning("Something Wrong! Please Try again");
    return {
      server: "server_issue",
    };
  }
}
