import axios from "axios";
import { toast } from "react-toastify";
import { refreshTokenUpdates } from "../HealthMonitor";
import { LocalStorageClear } from "../Auth";
import { store } from "../../index";

export const GetNotificationService = async (offset = 0) => {
  const state = store.getState();
  const userDetails =
    /*LocalStorageGetValue("userDetails")*/ state?.providerDetailsReducer
      ?.provider;
  const id = /*JSON.parse(userDetails)?.id*/ userDetails?.id;
  try {
    const response = await axios.post(
      `api/commonEndpoints/v1/getNotificationDetails`,
      {
        id: id,
        offset,
        timezoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }
    );
    return response.data;
  } catch (error) {
    errorTasks(error);
  }
};

export const GetSeverTime = async () => {
  try {
    const response = await axios.get(`api/patientEndpoints/v1/getCurrentTime`);
    return response.data;
  } catch (error) {
    errorTasks(error);
  }
};

function errorTasks(error) {
  if ([401, 504, 500, 704].includes(error.response.status)) {
    LocalStorageClear();
    sessionStorage.removeItem("currentPath");
    window.location.replace(window.location.href);
  }
  if ([703, 404].includes(error.response.status)) {
    refreshTokenUpdates(null);
    toast.warning("Something Wrong! Please Try again");
    return {
      server: "server_issue",
    };
  }
}
