import * as React from "react";
import "../../Styles/Typography/CustomTypography.scss";
import { Dialog, Typography, Grid } from "@material-ui/core";
import "./FeedBackStyles.scss";
import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";
import { styled } from "@mui/material/styles";
import StarIcon from "@mui/icons-material/Star";
import { callFeedBackAPI } from "../../Services/CommonService";
import { useSelector, useDispatch } from "react-redux";
import { callFeedBackHide } from "../../Redux/Actions/callActions";

const StyledRating = styled(Rating)({
  "& .MuiRating-icon": {
    color: "white",
  },
  "& .MuiRating-iconFilled": {
    color: "#9B51E0",
  },
  "& .MuiRating-iconHover": {
    color: "#9B51E0",
  },
});
export default function CallFeedBack() {
  const [value, setValue] = React.useState(0);
  const callFeedBackShow = useSelector(
    (state) => state.CallReducerShow.callFeedBackShow
  );
  const providerId = /*JSON.parse(LocalStorageGetValue("id"))*/ useSelector(
    (state) => state?.providerDetailsReducer?.provider?.id
  );
  const auditId = useSelector((state) => state.CallReducerShow.auditId);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!callFeedBackShow) {
      setValue(0);
    }
  }, [callFeedBackShow]);

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={callFeedBackShow}
      style={{ zIndex: 1299 }}
    >
      <div className="FeedBack-Dialog">
        <Grid container spacing={0} className="FeedBack-root-grid">
          <Grid item xs={12} sm={12} className="FeedBack-grid-div6">
            <Typography className="header-h3">
              {"Rate the quality of your call"}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} className="FeedBack-grid-div7">
            <Box>
              <StyledRating
                name="simple-controlled"
                value={value}
                size="large"
                onChange={(event, newValue) => {
                  setValue(newValue);
                }}
                icon={<StarIcon stroke={"#9B51E0"} fontSize="inherit" />}
                emptyIcon={<StarIcon stroke={"#9B51E0"} fontSize="inherit" />}
              />
            </Box>
          </Grid>
          <div className="FeedBack-footer-root-div">
            <div className="FeedBack-footer-div2">
              <Grid item xs={6} sm={6}>
                <div
                  className="FeedBack-btn"
                  style={{
                    cursor: "pointer",
                    textTransform: "initial",
                    whiteSpace: "nowrap",
                    width: "100%",
                    height: "100%",
                  }}
                  onClick={() => {
                    dispatch(callFeedBackHide());
                  }}
                >
                  <Typography className="content-emphasized-sec-text">
                    {"Not Now"}
                  </Typography>
                </div>
              </Grid>
            </div>
            <div className="FeedBack-line-div"></div>
            <div className="FeedBack-footer-div2">
              <Grid item xs={6} sm={6}>
                <div
                  className="FeedBack-btn"
                  style={{
                    cursor: "pointer",
                    textTransform: "initial",
                    whiteSpace: "nowrap",
                    width: "100%",
                    height: "100%",
                  }}
                  onClick={async () => {
                    await callFeedBackAPI({
                      providerId,
                      auditId,
                      feedBack: value,
                    });
                    dispatch(callFeedBackHide());
                  }}
                >
                  <Typography className="content-emphasized-sec-text">
                    {"Submit"}
                  </Typography>
                </div>
              </Grid>
            </div>
          </div>
        </Grid>
      </div>
    </Dialog>
  );
}
