import axios from "axios";
import { memoize } from "lodash";

const AESEncryptionKey = memoize(() => {
  // if (process.env.NODE_ENV === "development") {
  //   axios.defaults.baseURL = "https://qa-omnicure.web.app/";
  // }
  return axios
    .get("/api/userEndpoints/v1/versioninforesponse/Website")
    .then(({ data: { aesKey } }) => {
      return aesKey;
    });
});

const iv = crypto.getRandomValues(new Uint8Array(12));
let secretKey;

AESEncryptionKey().then((aesKey) => {
  const msgUint8 = new TextEncoder().encode(aesKey);
  return window.crypto.subtle.digest("SHA-256", msgUint8).then((value) => {
    window.crypto.subtle
      .importKey("raw", value, "AES-GCM", false, ["encrypt", "decrypt"])
      .then(function (key) {
        secretKey = key;
      })
      .catch(function (err) {
        console.error(err);
      });
  });
});

export function AES256_GCM_ENCRYPT(params) {
  if (secretKey === null || secretKey === undefined) {
    return AESEncryptionKey()
      .then((aesKey) => {
        const msgUint8 = new TextEncoder().encode(aesKey);
        return window.crypto.subtle
          .digest("SHA-256", msgUint8)
          .then((value) => {
            return window.crypto.subtle
              .importKey("raw", value, "AES-GCM", false, ["encrypt", "decrypt"])
              .then(function (key) {
                secretKey = key;
                return key;
              })
              .catch(function (err) {
                console.error(err);
              });
          });
      })
      .then(function (key) {
        secretKey = key;
        return key;
      })
      .then((secretKey) =>
        crypto.subtle.encrypt(
          {
            name: "aes-gcm",
            iv: iv,
            tagLength: 128, //can be 32, 64, 96, 104, 112, 120 or 128 (default)
          },
          secretKey,
          asciiToUint8Array(params)
        )
      )
      .then(function (cipherText) {
        const finalValue = _appendBuffer(iv, cipherText);
        const Base64String = arrayBufferToBase64(finalValue);
        return Promise.resolve(Base64String);
      });
  }
  return crypto.subtle
    .encrypt(
      {
        name: "aes-gcm",
        iv: iv,
        tagLength: 128, //can be 32, 64, 96, 104, 112, 120 or 128 (default)
      },
      secretKey,
      asciiToUint8Array(params)
    )
    .then(function (cipherText) {
      const finalValue = _appendBuffer(iv, cipherText);
      return arrayBufferToBase64(finalValue);
    }, failAndLog);
}

export function AES256_GCM_decrypt({ data: { encryptedValue } }) {
  if (secretKey == null) {
    alert("Failed to Generate AES Keys Check The browser Comptabilty ");
    return;
  }

  const cipherText = encryptedValue;
  const cipherTextIV = cipherText.substring(0, 16);
  const cipherTextValue = cipherText.substring(16);
  const arrayBuffercipherTextIV = base64ToArrayBuffer(cipherTextIV);
  const arrayBuffercipherTextValue = base64ToArrayBuffer(cipherTextValue);
  return crypto.subtle
    .decrypt(
      {
        name: "aes-gcm",
        iv: arrayBuffercipherTextIV,
        tagLength: 128, //can be 32, 64, 96, 104, 112, 120 or 128 (default)
      },
      secretKey,
      arrayBuffercipherTextValue
    )
    .then(
      function (plainText) {
        return bytesToASCIIString(plainText);
      },
      function (result) {
        return result;
      }
    );
}

function bytesToASCIIString(bytes) {
  return new TextDecoder().decode(new Uint8Array(bytes));
}

function failAndLog(error) {
  console.log(error);
}

function asciiToUint8Array(str) {
  let chars = [];
  for (let i = 0; i < str.length; ++i) chars.push(str.charCodeAt(i));
  return new Uint8Array(chars);
}

function arrayBufferToBase64(buffer) {
  let binary = "";
  let bytes = new Uint8Array(buffer);
  let len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

function base64ToArrayBuffer(base64) {
  let binary_string = window.atob(base64);
  let len = binary_string.length;
  let bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i);
  }
  return bytes.buffer;
}

function _appendBuffer(buffer1, buffer2) {
  let tmp = new Uint8Array(buffer1.byteLength + buffer2.byteLength);
  tmp.set(new Uint8Array(buffer1), 0);
  tmp.set(new Uint8Array(buffer2), buffer1.byteLength);
  return tmp.buffer;
}
