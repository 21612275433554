import axios from "axios";
import { sortBy, prop } from "ramda";
import { LoginRefresh } from "../../Services/Auth";
import { agoraSupportCode } from "./userAction";
export function saveProviderDetails(payload) {
  return {
    type: "SAVEPROVIDERDETAILS",
    payload,
  };
}

export function updateProviderDetails(payload) {
  return {
    type: "UPDATEPROVIDERDETAILS",
    payload,
  };
}

export function setSpecialityData(payload) {
  return {
    type: "SET_SPECIALITYDATA",
    payload,
  };
}

export function summaryEnoteTemplate(payload) {
  return {
    type: "SUMMARYENOTETEMPLATE",
    payload,
  };
}

export function storeFirebaseUserDetails(payload) {
  return {
    type: "STOREFIREBASEDETAILS",
    payload,
  };
}

export function storeIdToken(payload) {
  return {
    type: "STOREIDTOKEN",
    payload,
  };
}

export function storeRefreshToken(payload) {
  return {
    type: "STOREREFRESHTOKEN",
    payload,
  };
}

export function storePasswordChangeProviderDetails(payload) {
  return {
    type: "PASSWORDPROVIDERDETAILS",
    payload,
  };
}

export function storeAgoraAppId(payload) {
  return {
    type: "AGORAAPPID",
    payload,
  };
}

export function storeProviderToken(payload) {
  return {
    type: "STOREPROVIDERTOKEN",
    payload,
  };
}

export const specialityData = () => async (dispatch, getState) => {
  const {
    providerDetailsReducer: {
      provider: { userId: id },
    },
  } = getState();
  try {
    const {
      data: { specialtyTypes },
    } = await axios.post("api/commonEndpoints/v1/getAllSpecialtyTypes", { id });
    dispatch(setSpecialityData(sortBy(prop("order"), specialtyTypes)));
  } catch (error) {}
};

export function loginRefresh(uid) {
  return function (dispatch) {
    return LoginRefresh(uid).then((res) => {
      const {
        data: {
          provider,
          provider: { token },
          status,
          errorMessage,
          agoraSupportAreaCode,
          sampleAssessmentEnote,
          samplePlanEnote,
          agoraAppId,
          tutorial_url,
          webMicEnable,
        },
      } = res;
      if (status) {
        dispatch(
          saveProviderDetails({ ...provider, tutorial_url, webMicEnable })
        );
        dispatch(agoraSupportCode(agoraSupportAreaCode));
        dispatch(
          summaryEnoteTemplate({
            assessmentEnoteTemplate: sampleAssessmentEnote ?? "",
            planEnoteTemplate: samplePlanEnote ?? "",
          })
        );
        dispatch(storeProviderToken(token));
        dispatch(storeAgoraAppId(agoraAppId));
      } else {
        throw new Error(errorMessage);
      }
    });
  };
}
