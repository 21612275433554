import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { Backdrop, makeStyles } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));
export default function LoaderDialog({ loader, zIndex = 1203 }) {
  const classes = useStyles();
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Backdrop className={classes.backdrop} open={loader} style={{ zIndex }}>
        <CircularProgress
          style={{ color: "#9b51e0" }}
          aria-label="loader"
          id="loader"
        />
      </Backdrop>
    </div>
  );
}
