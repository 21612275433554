import { useEffect } from "react";
import { getDatabase, onValue, ref, set } from "firebase/database";

function useFireBaseCallServices({
  role,
  agoraCallDetails: { auditId },
  handleCallReject,
  handleCallNotAnswer,
  firebaseUid,
}) {
  useEffect(() => {
    if (firebaseUid) {
      //let authUnsubscribe;
      //const auth = getAuth();
      // const {
      //   promise: authPromise,
      //   resolve: authResolve,
      //   reject: authReject,
      // } = Promise.withResolvers();
      // onAuthStateChanged(auth, (user) => {
      //   authResolve(user);
      // });
      // const userData = await authPromise;
      const database = getDatabase();
      const starCountRef = ref(
        database,
        role === "thirdParty"
          ? `guest/${firebaseUid}/notification`
          : "providers/" + firebaseUid + "/notification"
      );
      const databaseListenerUnsubscribe = onValue(
        starCountRef,
        (snapshot) => {
          const data = snapshot.val();
          if (auditId === data?.auditId) {
            const callUserProvider = JSON.parse(data.Provider ?? "{}");
            if (data.messageType === "CallerReject") {
              handleCallReject?.(callUserProvider);
              const database = getDatabase();
              set(
                ref(
                  database,
                  role === "thirdParty"
                    ? `guest/${firebaseUid}/notification`
                    : "providers/" + firebaseUid + "/notification"
                ),
                null
              );
            }
            if (data.messageType === "CallerNotAnswer") {
              handleCallNotAnswer?.(callUserProvider);
              const database = getDatabase();
              set(
                ref(
                  database,
                  role === "thirdParty"
                    ? `guest/${firebaseUid}/notification`
                    : "providers/" + firebaseUid + "/notification"
                ),
                null
              );
            }
          }
        },
        (err) => {
          console.log(err, "ERROR");
        }
      );
      return () => {
        databaseListenerUnsubscribe?.();
      };
    }
  }, [firebaseUid]);
}

export default useFireBaseCallServices;
