import axios from "axios";

const getThirdPartyCallData = async (message, providerId) => {
  try {
    const {
      data: { tutorial_url, status, errorMessage },
    } = await axios.post("/api/userEndpoints/v1/getOmnicureCallUrl", {
      message,
      providerId,
    });
    if (status) {
      //const urlData = new URL(tutorial_url);
      // const urlQueryData = new URLSearchParams(tutorial_url)
      // const channel = urlQueryData.get('channel');
      // const token = urlQueryData.get('token');
      // return {channel,token}
      return tutorial_url;
    }
    throw new Error(errorMessage);
  } catch (err) {
    throw err;
  }
};

const getThirdPartyCallInitiate = (passingObject) => {
  return axios.post("/api/familyMemberCallEndpoints/v1/getLink", passingObject);
};

export { getThirdPartyCallData, getThirdPartyCallInitiate };
