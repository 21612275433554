import React, { Component, useEffect, createRef, useRef } from "react";
import AgoraRTC from "agora-rtc-sdk-ng";
import { mergeDeepRight, forEachObjIndexed, dissocPath } from "ramda";
import { withStyles } from "@material-ui/core/styles";
import "./AgoraCalls.scss";
import { getDatabase, ref, set } from "firebase/database";
import { Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import Timer from "./Timer";
import Ringtone from "../../Assets/images/ringtone.mp3";
import { LAST_CONNECTION, ABORTED, DISCONNECTED } from "../../constant";
import call from "../../Assets/images/Call.png";
import collapseIcon from "../../Assets/images/Group 1171276202.png";
import expandIcon from "../../Assets/images/expand.png";
import mute from "../../Assets/images/Volume Down.png";
import video from "../../Assets/images/Video.png";
import videoOff from "../../Assets/images/Group 1171276208.png";
import muteOff from "../../Assets/images/Volume Off.png";
import microphone from "../../Assets/images/microphone.svg";
import "../../Styles/Typography/CustomTypography.scss";
import { connect } from "react-redux";
import { incomingCallAction } from "../../Redux/Actions/incomingCallAction";
import { checkingProviderStatus } from "../../Services/AudVidCalls/AudVidCalls";
import { LocalStorageGetValue } from "../../Services/Auth";
import {
  showmodel,
  showmaximum,
  showminimum,
  showMessage,
  callFeedBackShow,
} from "../../Redux/Actions/callActions";
import { collapse, Expandable } from "../layouts/collapseWindow";
import { callDisconnectSOSsaveCallAudit } from "../../Services/GroupCall/GroupCall";
import { HealthMonitorCheckOnCalls } from "../../Services/HealthMonitor";
let milliSeconds = 2 * 60 * 1000;
let reconnectingStatus = "";
const styles = (theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  colorPrimary: {
    color: "#FFF",
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
});
const PlayerComponent = ({
  AudioTrack,
  VideoTrack,
  local,
  mainWindow,
  size,
}) => {
  const playerRef = useRef(null);
  useEffect(() => {
    if (VideoTrack) {
      if (!VideoTrack.isPlaying) {
        VideoTrack?.play && VideoTrack.play(playerRef.current, { fit: size });
        if (playerRef.current?.querySelector("video")) {
          playerRef.current.querySelector(
            "video"
          ).disablePictureInPicture = true;
        }
      }
    }
    if (AudioTrack) {
      if (!AudioTrack.isPlaying) {
        if (!local) {
          AudioTrack.play && AudioTrack.play();
        }
      }
    }
    // eslint-disable-next-line
  }, [AudioTrack, VideoTrack]);
  if (VideoTrack) {
    return (
      <div
        ref={playerRef}
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          borderBottomLeftRadius: "10px",
          borderBottomRightRadius: "10px",
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",
        }}
      ></div>
    );
  } else {
    return <div></div>;
  }
};
function supportAreaCode(params) {
  let supportedAreasArray = [];
  for (const element of params) {
    if (element === "IN") {
      supportedAreasArray.push("INDIA");
    } else if (element === "NA") {
      supportedAreasArray.push("NORTH_AMERICA");
    } else if (element === "AS") {
      supportedAreasArray.push("ASIA");
    } else if (element === "EU") {
      supportedAreasArray.push("EUROPE");
    } else if (element === "JP") {
      supportedAreasArray.push("JAPAN");
    } else if (element === "CN") {
      supportedAreasArray.push("CHINA");
    } else if (element === "GL") {
      supportedAreasArray.push("GLOBAL");
    }
  }
  return supportedAreasArray;
}
// let listUid = [];
class SOSAgoraCall extends Component {
  constructor(props) {
    super(props);
    this.state = {
      local: [],
      remoteUsers: {},
      remote: [],
      userIDList: [],
      userList: [],
      agoraRTCtoken: null,
      agoraReconnecting: false,
      reciverId: null,
      openCallModal: false,
      audioOnly: true,
      otherEnd: "",
      thisEnd: "",
      enableTimer: false,
      enablePlayer: true,
      showCallEnded: false,
      audio: new Audio(Ringtone),
      startAudio: "",
      agorasupportcode: props.AGORASUPPORTCODE?.split(","),
    };
    this.myRef = createRef();
    this.AgoraClient = AgoraRTC.createClient({ codec: "h264", mode: "rtc" });
    AgoraRTC.setArea(supportAreaCode(this.state.agorasupportcode));
    this.props.showCallMessage("reset", false, "");
  }

  initJoin = async (appID, channelName, tokenId, UID) => {
    await this.AgoraClient.join(appID, channelName, tokenId, parseInt(UID));
    // listUid = [];
    // listUid.push(UID);
    // if(this.AgoraClient.remoteUsers.length>1){
    //   if(this.AgoraClient._uid == UID){
    //     this.notAllowed()
    //     toast.warn("SOS call already accepted by another remote provider");
    //   }
    // }
    this.removeNotificationOnLogin();
  };
  removeNotificationOnLogin = () => {
    //const dbRef1 = Firebase.database().ref("providers/");
    const userDetails =
      /*JSON.parse(LocalStorageGetValue("user"))*/ this.props.firebaseDetails;
    const database = getDatabase();
    set(ref(database, `providers/${userDetails.uid}/notification`), null);
    //dbRef1.child(userDetails.uid).child("notification").set(null);
  };

  publishLocalStream = async (typeofCall) => {
    if (typeofCall === "audio_call") {
      await AgoraRTC.createMicrophoneAudioTrack()
        .then(async (localAudioTrack) => {
          this.setState({ local: [localAudioTrack], muteAudio: false });
          this.AgoraClient.publish([localAudioTrack]);
        })
        .catch((error) => {
          this.setState({ local: [], muteAudio: true });
        });
    } else if (typeofCall === "video_call") {
      await AgoraRTC.createMicrophoneAndCameraTracks()
        .then(async (track) => {
          let [localAudioTrack, localVideoTrack] = track;
          this.setState({
            local: [localAudioTrack, localVideoTrack],
            muteAudio: false,
            muteVideo: false,
          });
          this.AgoraClient.publish([localAudioTrack, localVideoTrack]);
        })
        .catch((error) => {
          this.setState({ local: [] });
        });
    }
  };
  remoteVideoOnlyTimer = () => {
    if (this.props.popUpWindow.CallMinMax.showMaxi) {
      if (window.innerWidth < 1150) {
        setTimeout(() => {
          if (document.getElementsByClassName("timerAudio").length > 0) {
            document.getElementsByClassName("timerAudio")[0].style.top = "18%";
            document.getElementsByClassName("timerAudio")[0].style.left =
              "25px";
            document.getElementsByClassName("timerAudio")[0].style.zIndex = 100;
            document.getElementsByClassName("timerAudio")[0].style.transform =
              "translate(-50%,-50%)";
          }
        }, 0);
      } else {
        setTimeout(() => {
          if (document.getElementsByClassName("timerAudio").length > 0) {
            document.getElementsByClassName("timerAudio")[0].style.top = "55px";
            document.getElementsByClassName("timerAudio")[0].style.left =
              "27px";
            document.getElementsByClassName("timerAudio")[0].style.zIndex = 100;
            document.getElementsByClassName("timerAudio")[0].style.transform =
              "translate(-50%,-50%)";
          }
        }, 0);
      }
    }
    if (!this.props.popUpWindow.CallMinMax.showMaxi) {
      setTimeout(() => {
        if (document.getElementsByClassName("timerAudio").length > 0) {
          document.getElementsByClassName("timerAudio")[0].style.top = "24%";
          document.getElementsByClassName("timerAudio")[0].style.left = "7%";
          document.getElementsByClassName("timeRun")[0].style.fontSize = "12px";
          document.getElementsByClassName("timerAudio")[0].style.zIndex = 100;
        }
      }, 0);
    }
  };
  localVideoOnlyTimert = () => {
    if (this.props.popUpWindow.CallMinMax.showMaxi) {
      setTimeout(() => {
        if (document.getElementsByClassName("timerAudio").length > 0) {
          document.getElementsByClassName("timerAudio")[0].style.top = "37%";
          document.getElementsByClassName("timerAudio")[0].style.left = "49%";
          document.getElementsByClassName("timerAudio")[0].style.zIndex = 100;
        }
      }, 0);
    }
    if (!this.props.popUpWindow.CallMinMax.showMaxi) {
      setTimeout(() => {
        if (document.getElementsByClassName("timerAudio").length > 0) {
          document.getElementsByClassName("timerAudio")[0].style.top = "15%";
          document.getElementsByClassName("timerAudio")[0].style.left = "49%";
          document.getElementsByClassName("timerAudio")[0].style.zIndex = 100;
        }
        if (document.getElementsByClassName("receiverName").length > 0) {
          document.getElementsByClassName("receiverName")[0].style.top = "32%";
          document.getElementsByClassName("receiverName")[0].style.width =
            "52px";
          document.getElementsByClassName("receiverName")[0].style.height =
            "52px";
          if (document.getElementsByClassName("innerCircle")) {
            document.getElementsByClassName("innerCircle")[0].style.border = 0;
            document.getElementsByClassName("innerCircle")[0].style.fontSize =
              "18px";
          }
          document.getElementsByClassName("receiverProfile")[0].style.left =
            "52%";
          document.getElementsByClassName("receiverProfile")[0].style.top =
            "52%";
        }
      }, 0);
    }
  };
  bothVideoOnlyTimert = () => {
    if (this.props.popUpWindow.CallMinMax.showMaxi) {
      if (window.innerWidth < 1150) {
        setTimeout(() => {
          if (document.getElementsByClassName("timerAudio").length > 0) {
            document.getElementsByClassName("timerAudio")[0].style.top = "55px";
            document.getElementsByClassName("timerAudio")[0].style.left =
              "27px";
            document.getElementsByClassName("timerAudio")[0].style.zIndex = 100;
            document.getElementsByClassName("timerAudio")[0].style.transform =
              "translate(-50%,-50%)";
          }
        }, 0);
      } else {
        setTimeout(() => {
          if (document.getElementsByClassName("timerAudio").length > 0) {
            document.getElementsByClassName("timerAudio")[0].style.top = "10%";
            document.getElementsByClassName("timerAudio")[0].style.left =
              "25px";
            document.getElementsByClassName("timerAudio")[0].style.zIndex = 100;
            document.getElementsByClassName("timerAudio")[0].style.transform =
              "translate(-50%,-50%)";
          }
        }, 0);
      }
    }
    if (!this.props.popUpWindow.CallMinMax.showMaxi) {
      setTimeout(() => {
        if (document.getElementsByClassName("timerAudio").length > 0) {
          document.getElementsByClassName("timerAudio")[0].style.top = "2%";
          document.getElementsByClassName("timerAudio")[0].style.left = "7%";
          document.getElementsByClassName("timeRun")[0].style.fontSize = "12px";
          document.getElementsByClassName("timerAudio")[0].style.zIndex = 100;
        }
      }, 0);
    }
  };
  audioOnlyTimer = () => {
    setTimeout(() => {
      if (
        document.getElementsByClassName("timerAudio").length > 0 &&
        this.props.popUpWindow.CallMinMax.showMaxi
      ) {
        let profilePic = document.getElementsByClassName("receiverName");
        if (profilePic && profilePic.length > 0) {
          if (
            this.props.popUpWindow.CallReducerShow.agoraCallsData.profilePicUrl
          ) {
            profilePic[0].childNodes[0].style.height = "84px";
            profilePic[0].childNodes[0].style.width = "84px";
          }
        }
        if (window.innerWidth > 1150) {
          document.getElementsByClassName("timerAudio")[0].style.top = "37%";
          document.getElementsByClassName("timerAudio")[0].style.left = "49%";
          document.getElementsByClassName("timerAudio")[0].style.zIndex = 100;
        } else {
          document.getElementsByClassName("timerAudio")[0].style.top = "18%";
          document.getElementsByClassName("timerAudio")[0].style.left = "48%";
          document.getElementsByClassName("timerAudio")[0].style.zIndex = 100;
          if (document.getElementsByClassName("receiverName").length > 0) {
            document.getElementsByClassName("receiverName")[0].style.top =
              "32%";
            document.getElementsByClassName("receiverName")[0].style.width =
              "52px";
            document.getElementsByClassName("receiverName")[0].style.height =
              "52px";
          }
          if (document.getElementsByClassName("innerCircle").length > 0) {
            document.getElementsByClassName("innerCircle")[0].style.border = 0;
            document.getElementsByClassName("innerCircle")[0].style.fontSize =
              "18px";
          }
          if (document.getElementsByClassName("receiverProfile").length > 0) {
            document.getElementsByClassName("receiverProfile")[0].style.left =
              "50%";
            document.getElementsByClassName("receiverProfile")[0].style.top =
              "48%";
          }
        }
      }
      if (
        document.getElementsByClassName("timerAudio").length > 0 &&
        !this.props.popUpWindow.CallMinMax.showMaxi
      ) {
        if (window.innerWidth < 1150) {
          document.getElementsByClassName("timerAudio")[0].style.top = "14%";
          document.getElementsByClassName("timerAudio")[0].style.left = "47%";
          document.getElementsByClassName("timerAudio")[0].style.zIndex = 100;
        } else {
          document.getElementsByClassName("timerAudio")[0].style.left = "49%";
          document.getElementsByClassName("timerAudio")[0].style.zIndex = 100;
          document.getElementsByClassName("timerAudio")[0].style.top = "13%";
          document.getElementsByClassName("timerAudio")[0].style.transform =
            "translate(-51%, -50%)";
          let profilePic = document.getElementsByClassName("receiverName");
          if (profilePic && profilePic.length > 0) {
            profilePic[0].childNodes[0].style.height = "52px";
            profilePic[0].childNodes[0].style.width = "52px";
          }
        }
        if (document.getElementsByClassName("receiverName").length > 0) {
          document.getElementsByClassName("receiverName")[0].style.top = "32%";
          document.getElementsByClassName("receiverName")[0].style.width =
            "52px";
          document.getElementsByClassName("receiverName")[0].style.height =
            "52px";
        }
        if (document.getElementsByClassName("innerCircle").length > 0) {
          document.getElementsByClassName("innerCircle")[0].style.border = 0;
          document.getElementsByClassName("innerCircle")[0].style.fontSize =
            "18px";
        }
        if (document.getElementsByClassName("receiverProfile").length > 0) {
          document.getElementsByClassName("receiverProfile")[0].style.left =
            "50%";
          document.getElementsByClassName("receiverProfile")[0].style.top =
            "52%";
        }
      }
    }, 0);
  };
  changeVideo = async (condition) => {
    if (condition === "includeVideo") {
      const localVideoTrack = await AgoraRTC.createCameraVideoTrack();
      await this.AgoraClient.publish(localVideoTrack);
      this.setState(
        { local: [...this.state.local, localVideoTrack], audioOnly: false },
        () => {
          if (this.state.muteAudio) {
            if (this.state.local[0]) {
              this.state.local[0].setEnabled(false);
            }
          } else {
            if (this.state.local[0]) {
              this.state.local[0].setEnabled(true);
            }
          }
        }
      );
    }
    if (condition === "excludeVideo") {
      const videoTrack = await AgoraRTC.createCameraVideoTrack();
      // Temporarily turn off the camera captrue
      await videoTrack.setEnabled(false);
      this.state.local[1].setEnabled(false);
      let videoTarck = this.state.local[1];
      await this.AgoraClient.unpublish(videoTarck);
      let audioTrack = this.state.local[0];
      this.setState({ local: [audioTrack], audioOnly: true }, () => {
        if (this.state.muteAudio) {
          if (this.state.local[0]) {
            this.state.local[0].setEnabled(false);
          }
        } else {
          if (this.state.local[0]) {
            this.state.local[0].setEnabled(true);
          }
        }
      });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.remoteUsers !== this.state.remoteUsers) {
      let remoteStreamArray = [];
      forEachObjIndexed((value, key) => {
        remoteStreamArray.push([value.audio, value.video]);
      }, this.state.remoteUsers);
      this.setState({ remote: remoteStreamArray });
    }
  }

  componentDidMount = async () => {
    let { agoraCallsData } = this.props.popUpWindow.CallReducerShow;
    this.setState({
      openCallModal: this.props.openCallModal,
    });
    if (agoraCallsData.hasOwnProperty("receiverDetails")) {
      this.setState({
        reciverId: agoraCallsData.receiverDetails.receiverId,
        thisEnd: agoraCallsData.callInitiaterDetails,
        otherEnd: agoraCallsData.receiverDetails,
      });
    }
    this.interval = setInterval(this.MonitorSession, milliSeconds);
    this.InitiateCall();
    let startAudio = setInterval(() => {
      this.state.audio.play();
    }, 5000);
    this.setState({ startAudio });
    document
      .getElementsByClassName("closeCallWindow")?.[0]
      ?.addEventListener("click", this.disconnectCall);
  };

  MonitorSession = async () => {
    let token = /*LocalStorageGetValue("token");*/ this.props.providerToken;
    let id = /*LocalStorageGetValue("id")*/ this.props.selfProviderId;
    if (token && id) {
      await HealthMonitorCheckOnCalls();
    }
  };
  notAllowed = () => {
    this.myRef?.current?.stopTimer();
    let agoraCallsData = {
      appId: "",
      channelName: "",
      agoraToken: "",
      initiaterID: "",
      typeofCall: "",
      show: false,
      receiverName: "",
      collapse: false,
    };
    this.props.showCallMessage("reset", false, "");
    this.props.openCallWindow(agoraCallsData);
    if (this.state.local != null) {
      this.state.local[0] && this.state.local[0].close();
      this.state.local[1] && this.state.local[1].close();
    }
    this.setState({ enablePlayer: false, local: null });
    setTimeout(async () => {
      await this.AgoraClient.unpublish().catch(console.log);
      await this.AgoraClient.leave();
      this.AgoraClient.removeAllListeners();
    }, 3000);
    navigator.mediaDevices
      .getUserMedia({ video: false, audio: true })
      .then((mediaStream) => {
        const stream = mediaStream;
        stream.getTracks().forEach((track) => {
          track.stop();
        });
        stream.getTracks().forEach((track) => {
          if (track.readyState === "live" && track.kind === "video") {
            track.stop();
          }
        });
      });
    this.state.userList.forEach(async (users) => {
      await this.AgoraClient.unsubscribe(users);
    });
    this.props.enableCallIcons?.();
  };

  leaveCall = async (rejectMessage) => {
    this.myRef?.current?.stopTimer();
    let agoraCallsData = {
      appId: "",
      channelName: "",
      agoraToken: "",
      initiaterID: "",
      typeofCall: "",
      show: false,
      receiverName: "",
      collapse: false,
    };
    let id =
      /*JSON.parse(LocalStorageGetValue("id"))*/ this.props.selfProviderId;
    let patientId = JSON.parse(LocalStorageGetValue("patientId"));
    this.props.showCallMessage("reset", false, "");
    this.props.openCallWindow(agoraCallsData);
    if (this.state.local != null) {
      this.state.local[0] && this.state.local[0].close();
      this.state.local[1] && this.state.local[1].close();
    }
    this.setState({ enablePlayer: false, local: null });
    setTimeout(async () => {
      await this.AgoraClient.unpublish().catch(console.log);
      await this.AgoraClient.leave();
      this.AgoraClient.removeAllListeners();
    }, 3000);
    navigator.mediaDevices
      .getUserMedia({ video: false, audio: true })
      .then((mediaStream) => {
        const stream = mediaStream;
        stream.getTracks().forEach((track) => {
          track.stop();
        });
        stream.getTracks().forEach((track) => {
          if (track.readyState === "live" && track.kind === "video") {
            track.stop();
          }
        });
      });
    this.state.userList.forEach(async (users) => {
      await this.AgoraClient.unsubscribe(users);
    });
    let testToken = /*LocalStorageGetValue("token");*/ this.props.providerToken;
    let ids =
      /*JSON.parse(LocalStorageGetValue("id"))*/ this.props.selfProviderId;
    const url = `api/providerEndpoints/v1/sendMessage`;
    let params = {
      providerId: ids,
      token: testToken.replace(/"/g, ""),
      receiverId: this.state.reciverId,
      message: rejectMessage,
      type: "callEnd",
      auditId: this.props.auditId,
    };
    if (rejectMessage !== null && rejectMessage === undefined) {
      params = {
        providerId: ids,
        token: testToken.replace(/"/g, ""),
        receiverId: this.state.reciverId,
        message: rejectMessage,
        type: "callEnd",
        auditId: this.props.auditId,
      };
    }
    if (this.state.remote.length < 1) {
      let url = `api/providerEndpoints/v1/sendMessageOnTopic`;
      let params = {
        providerId: id,
        token: testToken,
        message: patientId,
        type: "sos_dismiss",
      };
      checkingProviderStatus(url, params);
    } else {
      checkingProviderStatus(url, params).then(() => {
        this.setState({ openCallModal: false });
      });
    }
    callDisconnectSOSsaveCallAudit(this.props.auditId);
    this.props.enableCallIcons?.();
  };
  removeMicAndVideo = async () => {
    this.myRef?.current?.stopTimer();
    this.setState({ showCallEnded: true });
    setTimeout(async () => {
      this.state.local?.[0] && this.state.local?.[0]?.close?.();
      this.state.local?.[1] && this.state.local?.[1]?.close?.();
      await this.AgoraClient.unpublish().catch(console.log);
      await this.AgoraClient.leave();
      this.AgoraClient.removeAllListeners();
      this.setState({ enablePlayer: false, local: null, showCallEnded: false });
    }, 3000);
  };
  InitiateCall = async () => {
    let id =
      /*JSON.parse(LocalStorageGetValue("id"))*/ this.props.selfProviderId;
    let patientId = JSON.parse(LocalStorageGetValue("patientId"));
    let testToken = /*LocalStorageGetValue("token");*/ this.props.providerToken;
    this.AgoraClient.on("user-published", async (user, mediaType) => {
      this.setState((state) => {
        const { userList } = state;
        if (userList.findIndex((list) => list.uid === user.uid) === -1) {
          return { ...state, userList: [...userList, user] };
        }
        return { ...state };
      });
      // Initiate the subscription
      await this.AgoraClient.subscribe(user, mediaType);
      if (mediaType === "video") {
        const remoteVideoTrack = user.videoTrack;
        this.setState((state, props) => {
          let { remoteUsers } = state;
          remoteUsers = mergeDeepRight(
            { ...remoteUsers },
            { [user.uid]: { video: remoteVideoTrack } }
          );
          return {
            ...state,
            remoteUsers,
          };
        });
      }
      if (mediaType === "audio") {
        const remoteAudioTrack = user.audioTrack;
        this.setState((state, props) => {
          let { remoteUsers } = state;
          remoteUsers = mergeDeepRight(
            { ...remoteUsers },
            { [user.uid]: { audio: remoteAudioTrack } }
          );
          return {
            ...state,
            remoteUsers,
          };
        });
      }
    });
    this.AgoraClient.on(
      "connection-state-change",
      async (curState, revState, reason) => {
        if (curState === "RECONNECTING") {
          reconnectingStatus = setTimeout(() => {
            if (curState === "RECONNECTING") {
              this.leaveCall(LAST_CONNECTION);
              this.setState({ agoraReconnecting: false });
              clearInterval(reconnectingStatus);
            }
          }, 30000);
          this.setState({ agoraReconnecting: true });
        } else if (curState === "CONNECTED") {
          clearInterval(reconnectingStatus);
          this.setState({ agoraReconnecting: false });
          // const { appId, channelName, agoraToken, initiaterID, typeofCall } =
          //   this.props.popUpWindow.CallReducerShow.agoraCallsData;
          // await this.initJoin(appId, channelName, agoraToken, initiaterID);
          // await this.publishLocalStream(typeofCall);
        } else if (curState === "ABORTED") {
          this.leaveCall(ABORTED);
        } else if (curState === "DISCONNECTED") {
          navigator.mediaDevices
            .getUserMedia({ video: false, audio: true })
            .then((mediaStream) => {
              const stream = mediaStream;
              stream.getTracks().forEach((track) => {
                track.stop();
              });
              stream.getTracks().forEach((track) => {
                if (track.readyState === "live" && track.kind === "video") {
                  track.stop();
                }
              });
            });
        }
      }
    );
    this.AgoraClient.on("user-left", async ({ uid }, reason) => {
      let agoraCallsData = {
        appId: "",
        channelName: "",
        agoraToken: "",
        initiaterID: "",
        typeofCall: "",
        show: false,
        receiverName: "",
        collapse: false,
      };
      this.props.showCallMessage("reset", false, "");
      setTimeout(() => {
        this.props.openCallWindow(agoraCallsData);
      }, 0);
      this.setState((state, props) => {
        const { userIDList } = state;
        const itemIndex = userIDList.indexOf(uid);
        if (itemIndex !== -1) {
          const newOldList = [...userIDList];
          newOldList.splice(itemIndex, 1);
          this.removeMicAndVideo();
          return { ...state, userIDList: newOldList };
        }
        return state;
      });
    });
    this.AgoraClient.on("user-joined", async ({ uid }) => {
      // listUid.push(uid);
      let { agoraCallsData } = this.props.popUpWindow.CallReducerShow;
      clearInterval(this.state.startAudio);
      let receiverDetails = {};
      this.state.audio.pause();
      if (
        this.props.popUpWindow.CallReducerShow.agoraCallsData.sosCallInitiator
      ) {
        let url = `api/providerEndpoints/v1/sendMessageOnTopic`;
        let params = {
          providerId: id,
          token: testToken,
          message: patientId,
          type: "sos_dismiss",
        };
        checkingProviderStatus(url, params);
      }
      if (agoraCallsData.hasOwnProperty("listOfRPs")) {
        let [joinedRP] = agoraCallsData.listOfRPs.filter(
          ({ id }) => id === uid
        );
        let twoLetters =
          joinedRP.fname.replace(/[\s'.-]/g, "")[0].toUpperCase() +
          joinedRP.lname.replace(/[\s'.-]/g, "")[0].toUpperCase();
        let name =
          joinedRP.name.length > 20
            ? joinedRP.name.slice(0, 20) + "..."
            : joinedRP.name;
        let role = joinedRP?.displayName || joinedRP.remoteProviderType;
        let profilePicUrl = joinedRP.profilePicUrl;
        let hospital = "Remote Clinician";
        receiverDetails = { name, hospital, twoLetters, role, profilePicUrl };
        this.setState({
          reciverId: joinedRP.id,
          thisEnd: agoraCallsData.callInitiaterDetails,
          otherEnd: receiverDetails,
        });
      }
      this.setState((state, props) => {
        const { userIDList } = state;
        if (!userIDList.includes(uid)) {
          return {
            ...state,
            userIDList: [...userIDList, uid],
            enableTimer: true,
          };
        }
        return state;
      });
    });
    this.AgoraClient.on("user-unpublished", async (user, mediaType) => {
      this.setState((state, props) => {
        let { remoteUsers } = state;
        remoteUsers = dissocPath([user.uid, mediaType], remoteUsers);
        return {
          ...state,
          remoteUsers,
        };
      });
    });
    const { appId, channelName, agoraToken, initiaterID, typeofCall } =
      this.props.popUpWindow.CallReducerShow.agoraCallsData;
    await this.initJoin(appId, channelName, agoraToken, initiaterID);
    await this.publishLocalStream(typeofCall);
  };
  componentWillUnmount() {
    let callStatus = {
      incomingCallStatus: false,
    };
    this.props.isIncoming(callStatus);
    if (this.state.local != null) {
      this.state.local[0] && this.state.local[0].close();
      this.state.local[1] && this.state.local[1].close();
    }
    clearInterval(reconnectingStatus);
    clearInterval(this.interval);
    clearInterval(this.state.startAudio);
    this.AgoraClient.leave();
    setTimeout(() => {
      this.AgoraClient.unpublish().catch(console.log);
      this.AgoraClient.off();
      this.props.maxi();
    }, 1000);
    //const dbRef1 = Firebase.database().ref("providers/");
    const userDetails =
      /*JSON.parse(LocalStorageGetValue("user"));*/ this.props.firebaseDetails;
    if (userDetails !== null && userDetails !== undefined) {
      if (userDetails.uid !== null && userDetails.uid !== undefined) {
        //dbRef1.child(userDetails.uid).child("notification").set(null);
        const database = getDatabase();
        set(ref(database, `providers/${userDetails.uid}/notification`), null);
      }
    }
    this.props.callFeedBackShow();
    document
      .getElementsByClassName("closeCallWindow")?.[0]
      ?.removeEventListener("click", this.disconnectCall);
  }
  handleCollapse = (value) => {
    if (value) {
      collapse();
      this.props.mini();
    } else {
      Expandable();
      if (window.innerWidth < 1150) {
        document.getElementsByClassName("timerAudio")[0].style.top = "18%";
        document.getElementsByClassName("timerAudio")[0].style.left = "48%";
        document.getElementsByClassName("timerAudio")[0].style.zIndex = 100;
        if (document.getElementsByClassName("receiverName").length > 0) {
          document.getElementsByClassName("receiverName")[0].style.top = "32%";
          document.getElementsByClassName("receiverName")[0].style.width =
            "52px";
          document.getElementsByClassName("receiverName")[0].style.height =
            "52px";
        }
        if (document.getElementsByClassName("innerCircle").length > 0) {
          document.getElementsByClassName("innerCircle")[0].style.border = 0;
          document.getElementsByClassName("innerCircle")[0].style.fontSize =
            "18px";
        }
        if (document.getElementsByClassName("receiverProfile").length > 0) {
          document.getElementsByClassName("receiverProfile")[0].style.left =
            "51%";
          document.getElementsByClassName("receiverProfile")[0].style.top =
            "48%";
        }
      }
      this.props.maxi();
    }
  };
  enableAudio = () => {
    if (
      this.state.local === null ||
      this.state.local === undefined ||
      this.state.local.length === 0
    ) {
      this.enablePermission(this.props.typeofCall);
    } else {
      if (this.state.local[0]) {
        this.state.local[0].setEnabled(true).then(() => {
          this.setState({ muteAudio: false });
        });
      }
    }
  };
  disableAudio = () => {
    if (this.state.local[0]) {
      this.state.local[0].setEnabled(false).then(() => {
        this.setState({ muteAudio: true });
      });
    }
  };

  enablePermission = async (typeofCall) => {
    if (typeofCall === "audio_call") {
      await AgoraRTC.createMicrophoneAudioTrack()
        .then(async (localAudioTrack) => {
          await this.AgoraClient.publish([localAudioTrack]);
          this.setState({ local: [localAudioTrack], muteAudio: false });
        })
        .catch((error) => {
          this.setState({ local: [] });
        });
    } else if (typeofCall === "video_call") {
      await AgoraRTC.createMicrophoneAndCameraTracks()
        .then(async (track) => {
          let [localAudioTrack, localVideoTrack] = track;
          await this.AgoraClient.publish([localAudioTrack, localVideoTrack]);
          this.setState({
            local: [localAudioTrack, localVideoTrack],
            muteAudio: false,
            muteVideo: false,
          });
        })
        .catch((error) => {
          this.setState({ local: [] });
        });
    }
  };
  videoToAudioCall = async (condition) => {
    if (condition === "includeVideo") {
      const localVideoTrack = await AgoraRTC.createCameraVideoTrack();
      await this.AgoraClient.publish(localVideoTrack);
      this.setState(
        { local: [...this.state.local, localVideoTrack], muteVideo: false },
        () => {
          if (this.state.muteAudio) {
            if (this.state.local[0]) {
              this.state.local[0].setEnabled(false);
            }
          } else {
            if (this.state.local[0]) {
              this.state.local[0].setEnabled(true);
            }
          }
        }
      );
    }
    if (condition === "excludeVideo") {
      const videoTrack = await AgoraRTC.createCameraVideoTrack();
      // Temporarily turn off the camera captrue
      await videoTrack.setEnabled(false);
      this.state.local[1].setEnabled(false);
      let videoTarck = this.state.local[1];
      await this.AgoraClient.unpublish(videoTarck);
      let audioTrack = this.state.local[0];
      this.setState({ local: [audioTrack], muteVideo: true }, () => {
        if (this.state.muteAudio) {
          if (this.state.local[0]) {
            this.state.local[0].setEnabled(false);
          }
        } else {
          if (this.state.local[0]) {
            this.state.local[0].setEnabled(true);
          }
        }
      });
    }
  };
  disconnectCall = () => {
    this.leaveCall(DISCONNECTED);
  };
  render() {
    let { showMaxi } = this.props.popUpWindow.CallMinMax;
    const {
      local,
      remote,
      muteAudio,
      muteVideo,
      audioOnly,
      otherEnd,
      thisEnd,
    } = this.state;
    let { callInitiaterDetails } =
      this.props.popUpWindow.CallReducerShow.agoraCallsData;
    let { showCallerMessage, message } = this.props.popUpWindow.CallReducerShow;
    let {
      callInitiaterTwoLetters,
      callInitiaterName,
      callInitiaterHospital,
      callInitPicture,
    } = callInitiaterDetails;
    if (local !== null && local !== undefined) {
      return (
        <div
          className={
            this.props.typeofCall === "audio_call"
              ? "audVidContainer bgcolor"
              : "audVidContainer"
          }
        >
          <div>
            <PlayerComponent
              AudioTrack={remote?.[0]?.[0]}
              VideoTrack={remote?.[0]?.[1]}
              TrackObject={remote[0] ? remote[0] : []}
              mainWindow
              size="cover"
            />
            {!showCallerMessage &&
            this.state.enableTimer &&
            Array.isArray(remote) ? (
              <p
                className={
                  remote?.[0]?.[1] === undefined && local.length > 0
                    ? "timerAudio"
                    : "timerAudio timerSeconndaryVideo"
                }
              >
                <Timer ref={this.myRef} />
              </p>
            ) : (
              !showCallerMessage && (
                <div className="connecting_status">
                  <p>Connecting</p>
                  <div class="lds-ellipsis">
                    <div className="firstDot"></div>
                    <div className="firstDot"></div>
                    <div className="firstDot"></div>
                    <div className="firstDot"></div>
                  </div>
                </div>
              )
            )}
            {!showCallerMessage && this.state.agoraReconnecting ? (
              <div className="connecting_status">
                <p>Reconnecting</p>
                <div class="lds-ellipsis">
                  <div className="firstDot"></div>
                  <div className="firstDot"></div>
                  <div className="firstDot"></div>
                  <div className="firstDot"></div>
                </div>
              </div>
            ) : (
              ""
            )}

            {showCallerMessage && (
              <div className="connecting_status">
                <p style={{ color: "white", fontSize: 16 }}>{message}</p>
              </div>
            )}
            {Object.keys(otherEnd).length > 0 &&
            local.length < 2 &&
            remote?.[0]?.[1] === undefined ? (
              <>
                <p className="receiverName">
                  {otherEnd.profilePicUrl ? (
                    <img
                      style={{ borderRadius: "50%" }}
                      height={"84px"}
                      width={"84px"}
                      src={otherEnd.profilePicUrl}
                    />
                  ) : (
                    <p className="innerCircle">{otherEnd.twoLetters}</p>
                  )}
                </p>
                <div className="receiverProfile">
                  <p>
                    {otherEnd.name}
                    <span>
                      {otherEnd.hospital === "Remote Clinician" ? ", " : ""}
                    </span>
                    <span style={{ paddingLeft: "1px" }}> {otherEnd.role}</span>
                    {remote.length > 0 && remote?.[0]?.[0] === undefined && (
                      <img
                        height="15px"
                        width="15px"
                        alt="Profile"
                        src={microphone}
                      />
                    )}
                  </p>
                  <p>{otherEnd.hospital?.replace("Provider", "Clinician")}</p>
                </div>
                {this.audioOnlyTimer()}
              </>
            ) : Object.keys(otherEnd).length > 0 &&
              local.length <= 2 &&
              remote?.[0]?.[1] !== undefined ? (
              <div className="receiverNameVideo">
                <p>
                  {otherEnd.name}{" "}
                  {otherEnd.hospital === "Remote Clinician" ? ", " : ""}
                  {otherEnd.role}
                  {remote.length > 0 && remote?.[0]?.[0] === undefined && (
                    <img
                      height="10px"
                      alt="Name"
                      width="10px"
                      src={microphone}
                    />
                  )}
                </p>
                <p>{otherEnd.hospital?.replace("Provider", "Clinician")}</p>
                {this.remoteVideoOnlyTimer()}
              </div>
            ) : Object.keys(otherEnd).length > 0 &&
              Array.isArray(local) &&
              remote?.[0]?.[1] === undefined ? (
              <>
                <p className="receiverName">
                  {otherEnd.profilePicUrl ? (
                    <img
                      style={{ borderRadius: "50%" }}
                      height={"84px"}
                      width={"84px"}
                      src={otherEnd.profilePicUrl}
                    />
                  ) : (
                    <p className="innerCircle">{otherEnd.twoLetters}</p>
                  )}
                </p>
                <div className="receiverProfile">
                  <p>
                    {otherEnd.name}
                    <span>
                      {otherEnd.hospital === "Remote Clinician" ? ", " : " "}
                    </span>
                    <span style={{ paddingLeft: "1px" }}>{otherEnd.role}</span>
                    {remote.length > 0 && remote?.[0]?.[0] === undefined && (
                      <img
                        height="15px"
                        width="15px"
                        alt="Profile"
                        src={microphone}
                      />
                    )}
                  </p>
                  <p>{otherEnd.hospital?.replace("Provider", "Clinician")}</p>
                </div>
                {this.localVideoOnlyTimert()}
              </>
            ) : Object.keys(otherEnd).length > 0 &&
              Array.isArray(local) &&
              remote?.[0]?.[1] !== undefined ? (
              <div className="receiverNameVideo">
                {/* {this.changeTimerPositionVideo()} */}
                <p>
                  {otherEnd.name}{" "}
                  {otherEnd.hospital === "Remote Clinician" ? ", " : ""}
                  {otherEnd.role}
                  {remote.length > 0 && remote?.[0]?.[0] === undefined && (
                    <img
                      height="15px"
                      width="15px"
                      alt="Name"
                      src={microphone}
                    />
                  )}
                </p>
                <p>{otherEnd.hospital?.replace("Provider", "Clinician")}</p>
                {this.bothVideoOnlyTimert()}
              </div>
            ) : (
              ""
            )}
            <div
              className={
                this.props.typeofCall === "audio_call" && audioOnly === true
                  ? "operation operationAudio"
                  : "operation"
              }
            >
              <div>
                {this.props.typeofCall === "video_call" ? (
                  muteVideo ? (
                    <div
                      className="agora-primary-button"
                      style={{
                        background:
                          remote?.[0]?.[1] === undefined
                            ? "rgba(255, 255, 255, 0.3)"
                            : "#172B4D",
                      }}
                      onClick={() => {
                        if (
                          local === null ||
                          local === undefined ||
                          local.length === 0
                        ) {
                          this.enablePermission(this.props.typeofCall);
                        } else {
                          this.videoToAudioCall("includeVideo");
                        }
                      }}
                      onTouchStart={() => {
                        if (
                          local === null ||
                          local === undefined ||
                          local.length === 0
                        ) {
                          this.enablePermission(this.props.typeofCall);
                        } else {
                          this.videoToAudioCall("includeVideo");
                        }
                      }}
                    >
                      <img
                        style={{ width: "22px", height: "22px" }}
                        alt="videoOff"
                        src={video}
                      />
                      <Typography className="captions-default">
                        Video on
                      </Typography>
                    </div>
                  ) : (
                    <div
                      className="agora-primary-button"
                      style={{
                        background:
                          remote?.[0]?.[1] === undefined
                            ? "rgba(255, 255, 255, 0.3)"
                            : "#172B4D",
                      }}
                      onClick={() => {
                        this.videoToAudioCall("excludeVideo");
                      }}
                      onTouchStart={() => {
                        this.videoToAudioCall("excludeVideo");
                      }}
                    >
                      <img
                        style={{ width: "26px", height: "26px" }}
                        alt="video"
                        src={videoOff}
                      />
                      <Typography className="captions-default">
                        Video off
                      </Typography>
                    </div>
                  )
                ) : audioOnly ? (
                  <div
                    className="agora-primary-button"
                    style={{
                      background:
                        remote?.[0]?.[1] === undefined
                          ? "rgba(255, 255, 255, 0.3)"
                          : "#172B4D",
                    }}
                    onClick={() => this.changeVideo("includeVideo")}
                    onTouchStart={() => this.changeVideo("includeVideo")}
                  >
                    <img
                      style={{ width: "23px", height: "22px" }}
                      alt="videoOff"
                      src={video}
                    />
                    <Typography className="captions-default">
                      Video on
                    </Typography>
                  </div>
                ) : (
                  <div
                    className="agora-primary-button"
                    style={{
                      background:
                        remote?.[0]?.[1] === undefined
                          ? "rgba(255, 255, 255, 0.3)"
                          : "#172B4D",
                    }}
                    onClick={() => this.changeVideo("excludeVideo")}
                    onTouchStart={() => this.changeVideo("excludeVideo")}
                  >
                    <img
                      style={{ width: "26px", height: "26px" }}
                      alt="video"
                      src={videoOff}
                    />
                    <Typography className="captions-default">
                      Video off
                    </Typography>
                  </div>
                )}
              </div>
              <div>
                {muteAudio ? (
                  <div
                    onClick={this.enableAudio}
                    onTouchStart={this.enableAudio}
                    className="agora-primary-button"
                    style={{
                      background:
                        remote?.[0]?.[1] === undefined
                          ? "rgba(255, 255, 255, 0.3)"
                          : "#172B4D",
                    }}
                  >
                    <img
                      style={{ width: "27px", height: "26px" }}
                      alt="muteOff"
                      src={mute}
                    />
                    <Typography className="captions-default">Unmute</Typography>
                  </div>
                ) : (
                  <div
                    onClick={this.disableAudio}
                    onTouchStart={this.disableAudio}
                    className="agora-primary-button"
                    style={{
                      background:
                        remote?.[0]?.[1] === undefined
                          ? "rgba(255, 255, 255, 0.3)"
                          : "#172B4D",
                    }}
                  >
                    <img
                      style={{ width: "27px", height: "26px" }}
                      alt="mute"
                      src={muteOff}
                    />
                    <Typography className="captions-default">Mute</Typography>
                  </div>
                )}
              </div>

              <div>
                {showMaxi ? (
                  <div
                    onClick={() => this.handleCollapse(true)}
                    onTouchStart={() => this.handleCollapse(true)}
                    className="agora-primary-button"
                    style={{
                      background:
                        remote?.[0]?.[1] === undefined
                          ? "rgba(255, 255, 255, 0.3)"
                          : "#172B4D",
                    }}
                  >
                    <img
                      style={{ width: "27px", height: "26px" }}
                      alt="collapse"
                      src={collapseIcon}
                    />
                    <Typography className="captions-default">
                      Minimize
                    </Typography>
                  </div>
                ) : (
                  <div
                    onClick={() => this.handleCollapse(false)}
                    onTouchStart={() => this.handleCollapse(false)}
                    className="agora-primary-button"
                    style={{
                      background:
                        remote?.[0]?.[1] === undefined
                          ? "rgba(255, 255, 255, 0.3)"
                          : "#172B4D",
                    }}
                  >
                    <img
                      style={{ width: "27px", height: "26px" }}
                      alt="collapse"
                      src={expandIcon}
                    />
                    <Typography className="captions-default">
                      Minimize
                    </Typography>
                  </div>
                )}
              </div>
              <div>
                <div
                  className="agora-secondary-button "
                  onClick={() => this.leaveCall(DISCONNECTED)}
                  onTouchStart={() => this.leaveCall(DISCONNECTED)}
                >
                  <img
                    style={{ width: "27px", height: "26px" }}
                    alt="call"
                    src={call}
                  />
                  <Typography className="captions-default">End call</Typography>
                </div>
              </div>
            </div>
          </div>
          {this.props.popUpWindow.CallMinMax.showMaxi && (
            <div>
              {local[1]?.["_enabled"] && local[1] !== undefined ? (
                <div className="remoteVideoScreen">
                  <PlayerComponent
                    AudioTrack={local[0]}
                    VideoTrack={local[1]}
                    local
                    size="cover"
                    className="remoteVideoScreenBorder"
                  />
                </div>
              ) : Object.keys(thisEnd).length > 0 &&
                remote?.[0]?.[1] !== undefined ? (
                <div className="remoteVideoScreen">
                  <p className="videoInitiator">
                    {callInitPicture ? (
                      <p>
                        <img
                          style={{ borderRadius: "50%" }}
                          height={"50px"}
                          width={"50px"}
                          src={callInitPicture}
                        />
                      </p>
                    ) : (
                      <p style={{ fontWeight: "bold" }}>
                        {callInitiaterTwoLetters.toUpperCase()}
                      </p>
                    )}
                    <p>{callInitiaterName}</p>
                    <p>
                      {callInitiaterHospital?.replace(
                        "Provider",
                        "Clinician"
                      ) || ""}
                    </p>
                  </p>
                </div>
              ) : (
                ""
              )}
            </div>
          )}
        </div>
      );
    } else {
      return null;
    }
  }
}
SOSAgoraCall.propTypes = {
  classes: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => {
  return {
    popUpWindow: state,
    AGORASUPPORTCODE: state.userReducer.AGORASUPPORTCODE,
    firebaseDetails: state?.providerDetailsReducer?.firebase,
    providerToken: state?.providerDetailsReducer?.token,
    selfProviderId: state?.providerDetailsReducer?.provider?.id,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    openCallWindow: (data) => dispatch(showmodel(data)),
    showCallMessage: (type, open, message) =>
      dispatch(showMessage(type, open, message)),
    maxi: () => {
      dispatch(showmaximum());
    },
    mini: () => {
      dispatch(showminimum());
    },
    isIncoming: (data) => {
      dispatch(incomingCallAction(data));
    },
    callFeedBackShow() {
      dispatch(callFeedBackShow());
    },
  };
};
export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(SOSAgoraCall)
);
