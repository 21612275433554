import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import reducer from "../src/Redux/Reducer";
import "./i18n";
import { MuiThemeProvider } from "@material-ui/core/styles";
import GlobalTheme from "./GlobalTheme.js";
import { MemoryRouter } from "react-router";
import history from "./Pages/history/history";
import { createStore, applyMiddleware, compose } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import thunk from "redux-thunk";
import storage from "redux-persist/lib/storage/session";
import { PersistGate } from "redux-persist/integration/react";
import { firebaseConfig as firebaseConfigEnv } from "./firebase-config";
import { initializeApp } from "firebase/app";

const { speechToTextURL, ...firebaseConfig } = firebaseConfigEnv;

initializeApp(firebaseConfig);

const persistConfig = {
  key: "root",
  storage,
  whitelist: [
    "patientDetailsReducer",
    "userReducer",
    "archivePatientReducer",
    "MiscReducer",
    "BreadCrumbReducer",
  ], // define which storage to use
};

let redux_middleware;
if (
  window.__REDUX_DEVTOOLS_EXTENSION__ &&
  process.env.NODE_ENV === "development"
) {
  redux_middleware = compose(
    applyMiddleware(thunk),
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  );
} else {
  redux_middleware = compose(applyMiddleware(thunk));
}

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = createStore(persistedReducer, redux_middleware);

const persistor = persistStore(store);
ReactDOM.render(
  <MemoryRouter history={history}>
    <MuiThemeProvider theme={GlobalTheme}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </MuiThemeProvider>
  </MemoryRouter>,
  document.getElementById("root")
);

reportWebVitals();
