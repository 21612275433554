import React, { Component, Suspense } from "react";
import { LayoutRoute, LaunchLayout, MainLayout } from "../src/Pages/layouts";
import { Redirect, Switch, Route, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import axios from "axios";
import { Typography, CircularProgress } from "@material-ui/core";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import "./App.scss";
import loadable, { lazy } from "@loadable/component";
import pMinDelay from "p-min-delay";
import { refreshTokenUpdates } from "../src/Services/HealthMonitor";
import { connect } from "react-redux";
import {
  AES256_GCM_ENCRYPT,
  AES256_GCM_decrypt,
} from "../src/Services/Auth/encyptionDecryption";
import { LocalStorageClear, LocalStorageGetValue } from "./Services/Auth";
import { bindActionCreators } from "redux";
import * as storeProviderDetailsAction from "./Redux/Actions/providerDetailsAction";
import { detect } from "detect-browser";

// if (process.env.NODE_ENV === "development") {
//   axios.defaults.baseURL = "https://qa-omnicure.web.app/";
// }
const { name, os, version } = detect();

const pageAccessedByReload =
  /*(window.performance.navigation && window.performance.navigation.type === 1) ||*/
  window.performance
    .getEntriesByType("navigation")
    .map((nav) => nav.type)
    .includes("reload");

const currentVersion = Number(version.split(".", 1)[0]);

const supportedBrowser =
  (name.match(/chrome|chromium|crios/i) && currentVersion >= 98) ||
  (name.match(/firefox|fxios/i) && currentVersion >= 98) ||
  (name.match(/edg/i) && currentVersion >= 100) ||
  (name.match(/safari/i) &&
    currentVersion >= 14 &&
    (os.match(/Windows/) || (os.match(/Mac/) && navigator.maxTouchPoints > 1)));

const loaderJSX = (
  <div
    style={{
      display: "grid",
      placeContent: "center",
      position: "absolute",
      width: "100%",
      height: !supportedBrowser
        ? "calc(100vh - 64px)"
        : "calc(100vh - 64px - 24px)",
      gridAutoFlow: "column",
      zIndex: 10,
      top: !supportedBrowser ? 64 : 88,
    }}
  >
    <CircularProgress open={true} />
    <Typography className="header-h3">{"Loading..."}</Typography>
  </div>
);

const loginLoaderJSX = (
  <div
    style={{
      display: "grid",
      placeContent: "center",
      position: "absolute",
      width: "100%",
      height: "100%",
      gridAutoFlow: "column",
      zIndex: 10,
      top: 0,
      left: 0,
      background: "#f8f8f8",
    }}
  >
    <CircularProgress open={true} />
    <Typography className="header-h3">{"Loading..."}</Typography>
  </div>
);

const Dashboard = loadable(
  () => pMinDelay(import("../src/Pages/Dashbboard/Dashboard"), 200),
  { fallback: loaderJSX }
);
const AddPatient = loadable(
  () => pMinDelay(import("./Pages/AddPatient/AddPatient"), 200),
  { fallback: loaderJSX }
);
const ResetPasswordScreen = loadable(
  () =>
    pMinDelay(import("../src/Pages/ForgotPassword/ResetPasswordScreen"), 200),
  { fallback: loginLoaderJSX }
);
const ExpiredPasswordScreen = loadable(
  () =>
    pMinDelay(
      import("../src/Pages/ExpiredPassword/ExpiredPasswordScreen"),
      200
    ),
  { fallback: loginLoaderJSX }
);
const NewPasswordScreen = loadable(
  () => pMinDelay(import("./Pages/ForgotPassword/NewPasswordScreen"), 200),
  { fallback: loginLoaderJSX }
);
const SuccessScreen = loadable(
  () => pMinDelay(import("./Pages/ForgotPassword/SuccessScreen"), 200),
  { fallback: loginLoaderJSX }
);
const SuccessRegisteration = loadable(
  () => pMinDelay(import("./Pages/SignUpPage/Forms/SuccessRegisteration"), 200),
  { fallback: loginLoaderJSX }
);
const LaunchComponent = lazy(() =>
  pMinDelay(import("../src/Pages/LaunchComponent"), 200)
);
const ExpiredSuccessScreen = loadable(
  () =>
    pMinDelay(import("../src/Pages/ExpiredPassword/ExpiredSuccessScreen"), 200),
  { fallback: loginLoaderJSX }
);
const SettingsScreen = loadable(
  () => pMinDelay(import("../src/Pages/Userprofile/Settings"), 200),
  { fallback: loaderJSX }
);
const Support = loadable(
  () => pMinDelay(import("../src/Pages/layouts/contactSupport"), 200),
  { fallback: loaderJSX }
);
const Feedback = loadable(
  () => pMinDelay(import("../src/Pages/layouts/feedback"), 200),
  { fallback: loaderJSX }
);
const VerifcationPending = loadable(
  () => pMinDelay(import("./Pages/Login/VerifcationPending"), 200),
  { fallback: loginLoaderJSX }
);
const OTPpagesLogin = loadable(
  () => pMinDelay(import("./Pages/Login/OTPpagesLogin"), 200),
  { fallback: loginLoaderJSX }
);
const ProviderDirectory = loadable(
  () => pMinDelay(import("./Pages/ProviderDirectory1"), 200),
  { fallback: loaderJSX }
);
const Econsult = loadable(
  () => pMinDelay(import("./Pages/Econsults/Econsult"), 200),
  {
    fallback: React.cloneElement(loaderJSX, {
      style: {
        display: "grid",
        placeContent: "center",
        position: "absolute",
        width: "100%",
        height: !supportedBrowser
          ? "calc(100vh - 64px - 49px)"
          : "calc(100vh - 64px - 24px - 49px)",
        gridAutoFlow: "column",
        zIndex: 10,
        top: !supportedBrowser ? 113 : 137,
      },
    }),
  }
);
const PatientCensus = loadable(
  () => pMinDelay(import("./Pages/PatientCensus/PatientCensusSection"), 200),
  { fallback: loaderJSX }
);
const NotificationDetails = loadable(
  () => pMinDelay(import("../src/Pages/Notification/notificationDetails"), 200),
  { fallback: loaderJSX }
);
const DashboardEconsultWrapper = loadable(
  () => pMinDelay(import("../src/Pages/Econsults/DashboardWrapper"), 200),
  { fallback: loaderJSX }
);
const PatientCensusEconsultWrapper = loadable(
  () => pMinDelay(import("../src/Pages/Econsults/PatientCensusWrapper"), 200),
  { fallback: loaderJSX }
);
const ArchiveDetails = loadable(
  () => pMinDelay(import("./Pages/ArchivedPatients/ArchiveDetails"), 200),
  { fallback: loaderJSX }
);
const ArchiveHomepage = loadable(
  () => pMinDelay(import("./Pages/ArchivedPatients/archivedHomepage"), 200),
  { fallback: loaderJSX }
);
const ConferenceHomePage = loadable(
  () => pMinDelay(import("./Pages/Conference/ConferenceHistory"), 200),
  { fallback: loaderJSX }
);
const AddConferencePage = loadable(
  () => pMinDelay(import("./Pages/Conference/AddConference"), 200),
  { fallback: loaderJSX }
);
const VitalsDashboardPage = loadable(
  () => pMinDelay(import("./Pages/VitalsDashboard"), 200),
  { fallback: loaderJSX }
);

const ThirdPartyCallWindow = loadable(
  () => pMinDelay(import("./Pages/ThirdPartyCall"), 200),
  { fallback: loaderJSX }
);
let currentPath = sessionStorage.getItem("currentPath");

/*
axios.interceptors.request.use(
  function (config) {
    return new Promise((resolve, reject) => {
      axios.defaults.headers.common["Content-Type"] = "application/json";
      const { data, method } = config;
      if (!process.env.REACT_APP_PRODUCTION_BUILD) {
        console.log(config.url, "config", data);
      }
      if (method === "post" && data && !data.hasOwnProperty("encryptedValue")) {
        AES256_GCM_ENCRYPT(JSON.stringify(data))
          .then((encryptedValue) => {
            const newConfig = { ...config, data: { encryptedValue } };
            resolve(newConfig);
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        resolve(config);
      }
    });
  },
  function (error) {
    return Promise.reject(error);
  },
  {
    runWhen: ({ url }) =>
      url !== "/api/userEndpoints/v1/versioninforesponse/Website",
  }
);

axios.interceptors.response.use(undefined, async function (error) {
  if (
    error?.response?.status === 502 ||
    error?.response?.status === 703 ||
    error?.response?.status === 704
  ) {
    try {
      let sAvailRefresh = LocalStorageGetValue("rToken");
      let passingObject = {
        token: sAvailRefresh,
      };
      const response = await axios.post(
        `/api/userEndpoints/v1/renewIdToken`,
        passingObject
      );
      const firebaseTokenTemp = response.data.idToken;
      const rTokenTemp = response.data.refreshToken;
      const idTokenTemp = response.data.idToken;
      LocalStorageSetValue("firebaseToken", firebaseTokenTemp);
      LocalStorageSetValue("rToken", rTokenTemp);
      LocalStorageSetValue("idToken", idTokenTemp);
      axios.defaults.headers.common["Authorization"] = idTokenTemp;
      error.config.headers.Authorization = idTokenTemp;
      const uninterceptedAxios = axios.create();
      return uninterceptedAxios(error.config);
    } catch (err) {
      LocalStorageClear();
      sessionStorage.removeItem("currentPath");
      this.props.history.push("/login");
      window.history.replaceState(null, "", "/");
    }
  }
  return Promise.reject(error);
});

axios.interceptors.response.use(
  function (response) {
    return new Promise((resolve, reject) => {
      if (response?.data?.encryptedValue) {
        AES256_GCM_decrypt({ data: response.data })
          .then((decryptedValue) => {
            if (!process.env.REACT_APP_PRODUCTION_BUILD) {
              console.log(
                response.config.url,
                JSON.parse(decryptedValue),
                "Response_Config"
              );
            }
            const newResponse = {
              ...response,
              data: JSON.parse(decryptedValue),
            };
            resolve(newResponse);
          })
          .catch((error) => {
            if ([703, 502, 404].includes(error.response.status)) {
              refreshTokenUpdates(null);
              toast.warning("Something Wrong! Please Try again");
              reject(error);
            }
          });
      } else {
        resolve(response);
      }
    });
  },
  function (error) {
    return Promise.reject(error);
  }
);
*/
//let token = LocalStorageGetValue("token");
class App extends Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  componentDidMount() {
    /*if(pageAccessedByReload){
    this.unsubscribeFirebase = Firebase.auth().onAuthStateChanged((user) => {
      if (user.uid) {
        this.props.loginRefresh(user.uid).then(() => {

          })
          .catch((err) => {
            debugger;
            Firebase.auth()
              .signOut()
              .then(() => {
                this.props.history.replace("/login");
                window.history.replaceState(null, "", "/");
              });
          });
      }
    });
  }*/
    if (window.location.pathname !== "/test/call") {
      if (pageAccessedByReload) {
        const currentPath = LocalStorageGetValue("currentPath");
        if (currentPath) {
          this.props.history.push(currentPath);
          window.history.replaceState(null, "", "/");
        }
      } else {
        this.props.history.push("/login");
        window.history.replaceState(null, "", "/");
      }
    } else {
      this.props.history.push("/test/call");
    }
    axios.interceptors.request.use(
      (config) => {
        return new Promise((resolve, reject) => {
          axios.defaults.headers.common["Content-Type"] = "application/json";
          const { data, method } = config;
          if (!process.env.REACT_APP_PRODUCTION_BUILD) {
            console.log(config.url, "config", data);
          }
          if (
            method === "post" &&
            data &&
            !data.hasOwnProperty("encryptedValue")
          ) {
            AES256_GCM_ENCRYPT(JSON.stringify(data))
              .then((encryptedValue) => {
                const newConfig = { ...config, data: { encryptedValue } };
                resolve(newConfig);
              })
              .catch((error) => {
                reject(error);
              });
          } else {
            resolve(config);
          }
        });
      },
      function (error) {
        return Promise.reject(error);
      },
      {
        runWhen: ({ url }) =>
          url !== "/api/userEndpoints/v1/versioninforesponse/Website",
      }
    );

    axios.interceptors.response.use(undefined, async (error) => {
      if (
        error?.response?.status === 502 ||
        error?.response?.status === 703 ||
        error?.response?.status === 704
      ) {
        try {
          let sAvailRefresh =
            /*LocalStorageGetValue("rToken");*/ this.props.refreshToken;
          let passingObject = {
            token: sAvailRefresh,
          };
          const response = await axios.post(
            `/api/userEndpoints/v1/renewIdToken`,
            passingObject
          );
          //const firebaseTokenTemp = response.data.idToken;
          const rTokenTemp = response.data.refreshToken;
          const idTokenTemp = response.data.idToken;
          //LocalStorageSetValue("firebaseToken", firebaseTokenTemp);
          this.props.storeIdToken(idTokenTemp);
          this.props.storeRefreshToken(rTokenTemp);
          //LocalStorageSetValue("rToken", rTokenTemp);
          //LocalStorageSetValue("idToken", idTokenTemp);
          axios.defaults.headers.common["Authorization"] = idTokenTemp;
          error.config.headers.Authorization = idTokenTemp;
          const uninterceptedAxios = axios.create();
          return uninterceptedAxios(error.config);
        } catch (err) {
          LocalStorageClear();
          sessionStorage.removeItem("currentPath");
          this.props.history.push("/login");
          window.history.replaceState(null, "", "/");
        }
      }
      return Promise.reject(error);
    });

    axios.interceptors.response.use(
      (response) => {
        return new Promise((resolve, reject) => {
          if (response?.data?.encryptedValue) {
            AES256_GCM_decrypt({ data: response.data })
              .then((decryptedValue) => {
                if (!process.env.REACT_APP_PRODUCTION_BUILD) {
                  console.log(
                    response.config.url,
                    JSON.parse(decryptedValue),
                    "Response_Config"
                  );
                }
                const newResponse = {
                  ...response,
                  data: JSON.parse(decryptedValue),
                };
                resolve(newResponse);
              })
              .catch((error) => {
                if ([703, 502, 404].includes(error.response.status)) {
                  refreshTokenUpdates(null);
                  toast.warning("Something Wrong! Please Try again");
                  reject(error);
                }
              });
          } else {
            resolve(response);
          }
        });
      },
      function (error) {
        return Promise.reject(error);
      }
    );
    /*
    const authToken = LocalStorageGetValue("idToken");
    axios.defaults.headers.common["Authorization"] = authToken;
    if (currentPath === null || currentPath === undefined) {
      if (token) {
        currentPath = sessionStorage.setItem("currentPath", "/dashboard");
      } else {
        currentPath = sessionStorage.setItem("currentPath", "/login");
      }
    }
    if (token) {
      if (currentPath !== null && currentPath !== undefined) {
        this.props.history.push(currentPath);
      } else {
        if (window.location.pathname !== "/login") {
          this.props.history.push("/dashboard");
        } else {
          this.props.history.push("/login");
        }
      }
    } else {
      this.props.history.push("/login");
    }
    window.history.replaceState(null, "", "/");
    */
    //window.history.replaceState(null, "", "/");
  }
  render() {
    return (
      <Suspense fallback={null}>
        <ToastContainer />
        <Switch>
          <LayoutRoute
            exact
            path="/login"
            layout={LaunchLayout}
            component={LaunchComponent}
          />
          <LayoutRoute
            exact
            path="/expired-success"
            layout={LaunchLayout}
            component={ExpiredSuccessScreen}
          />
          <LayoutRoute
            exact
            path="/register-success"
            layout={LaunchLayout}
            component={SuccessRegisteration}
          />

          <LayoutRoute
            exact
            path="/pending-otp"
            layout={LaunchLayout}
            component={OTPpagesLogin}
          />

          <LayoutRoute
            exact
            path="/reset-password"
            layout={LaunchLayout}
            component={ResetPasswordScreen}
          />
          <LayoutRoute
            exact
            path="/expired-password"
            layout={LaunchLayout}
            component={ExpiredPasswordScreen}
          />
          <LayoutRoute
            exact
            path="/new-password"
            layout={LaunchLayout}
            component={NewPasswordScreen}
          />
          <LayoutRoute
            exact
            path="/success"
            layout={LaunchLayout}
            component={SuccessScreen}
          />
          <LayoutRoute
            exact
            path="/settings"
            layout={MainLayout}
            component={SettingsScreen}
          />
          <LayoutRoute
            exact
            path="/dashboard"
            layout={MainLayout}
            component={Dashboard}
          />
          <LayoutRoute
            exact
            path="/provider-directory"
            layout={MainLayout}
            component={ProviderDirectory}
          />
          <LayoutRoute
            exact
            path="/support"
            layout={MainLayout}
            component={Support}
          />
          <LayoutRoute
            exact
            path="/feedback"
            layout={MainLayout}
            component={Feedback}
          />
          <LayoutRoute
            exact
            path="/verify-pending"
            layout={LaunchLayout}
            component={VerifcationPending}
          />
          <LayoutRoute
            exact
            path="/econsults"
            layout={MainLayout}
            component={(props) => (
              <DashboardEconsultWrapper
                EconsultComponent={Econsult}
                {...props}
              ></DashboardEconsultWrapper>
            )}
          />
          <LayoutRoute
            exact
            path="/econsults_PatientCensus"
            layout={MainLayout}
            component={(props) => (
              <PatientCensusEconsultWrapper
                EconsultComponent={Econsult}
                {...props}
              ></PatientCensusEconsultWrapper>
            )}
          />
          <LayoutRoute
            exact
            path="/patientCensus"
            layout={MainLayout}
            component={PatientCensus}
          />
          <LayoutRoute
            exact
            path="/notificationDetails"
            layout={MainLayout}
            component={NotificationDetails}
          />
          <LayoutRoute
            exact
            path="/addpatient"
            layout={MainLayout}
            component={AddPatient}
          />
          <LayoutRoute
            exact
            path="/archiveDetails"
            layout={MainLayout}
            component={ArchiveDetails}
          />
          <LayoutRoute
            exact
            path="/archiveHomepage"
            layout={MainLayout}
            component={ArchiveHomepage}
          />
          <LayoutRoute
            exact
            path="/conference"
            layout={MainLayout}
            component={ConferenceHomePage}
          />
          <LayoutRoute
            exact
            path="/addConference"
            layout={MainLayout}
            component={AddConferencePage}
          />
          <LayoutRoute
            exact
            path="/vitalsDashboard"
            layout={MainLayout}
            component={VitalsDashboardPage}
          />
          <Route
            path="/test/call"
            component={ThirdPartyCallWindow} /*render={()=>{
              console.log(channel,token,appid,"CONSOLES")
              if(true || (channel&&token&&appid)){
                return <ThirdPartyCallWindowWrapper><ThirdPartyCallWindow channel={channel} token={decodeURIComponent(token)} appid={appid} uid={Number(uid)} /></ThirdPartyCallWindowWrapper>
              }
              window.history.replaceState(null, "", "/");
                return <Redirect to={"/login"} />
           }}*/
          />
          <Redirect to={currentPath || "/login"} />
        </Switch>
      </Suspense>
    );
  }
}

const mapStateToProps = (state) => ({
  idToken: state?.providerDetailsReducer?.idToken,
  refreshToken: state?.providerDetailsReducer?.refreshToken,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(storeProviderDetailsAction, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
