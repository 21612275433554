import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import "../Styles/Typography/CustomTypography.scss";
import "../Styles/Buttons/Buttons.scss";
import "../Styles/font.css";
const styles = (theme) => ({
  root: {
    padding: 0,
  },
});

class LaunchComponentLable extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <Typography
          aria-label="Launch Header"
          id="header"
          className="header-h2"
        >
          {this.props.label}
        </Typography>
      </>
    );
  }
}

LaunchComponentLable.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withTranslation()(
  withStyles(styles, { withTheme: true })(LaunchComponentLable)
);
