import { useState, useLayoutEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { getAuth, signOut } from "firebase/auth";
import axios from "axios";
import { Typography, CircularProgress } from "@material-ui/core";
import {
  loginRefresh,
  storeIdToken,
  storeRefreshToken,
  storeFirebaseUserDetails,
} from "../../Redux/Actions/providerDetailsAction";
import { LocalStorageClear } from "../../Services/Auth";
import { AES256_GCM_ENCRYPT } from "../../Services/Auth/encyptionDecryption";

const loaderJSX = (
  <div
    style={{
      width: "100vw",
      height: "100vh",
      display: "grid",
      placeItems: "center",
      position: "absolute",
      zIndex: 10,
    }}
  >
    <div style={{ display: "flex", alignItems: "center" }}>
      <CircularProgress open />
      <Typography className="header-h3">{"Loading..."}</Typography>
    </div>
  </div>
);

const pageAccessedByReload =
  (window.performance.navigation && window.performance.navigation.type === 1) ||
  window.performance
    .getEntriesByType("navigation")
    .map((nav) => nav.type)
    .includes("reload");

export default function withFirebaseRefreshCheck(MainLayoutComponent) {
  return function (props) {
    const location = useLocation();
    const isNotNavigatedFromLogin = location.state !== "NavigationFromLogin";
    const [loading, setLoading] = useState(
      pageAccessedByReload && isNotNavigatedFromLogin
    );
    const dispatch = useDispatch();
    const history = useHistory();
    useLayoutEffect(() => {
      if (pageAccessedByReload && isNotNavigatedFromLogin) {
        const auth = getAuth();
        //const unsubscribeFirebase = Firebase.auth().onAuthStateChanged(
        auth
          .authStateReady()
          .then(() => auth.currentUser)
          .then(async (user) => {
            if (user?.uid) {
              const idToken = await user.getIdToken();
              const encryptedIdToken = await AES256_GCM_ENCRYPT(idToken);
              axios.defaults.headers.common["Authorization"] = encryptedIdToken;
              const refreshToken = user.refreshToken;
              const encryptedRefreshToken = await AES256_GCM_ENCRYPT(
                refreshToken
              );
              dispatch(storeIdToken(encryptedIdToken));
              dispatch(storeRefreshToken(encryptedRefreshToken));
              dispatch(
                storeFirebaseUserDetails(JSON.parse(JSON.stringify(user)))
              );
              dispatch(loginRefresh(user.uid))
                .then(() => {
                  setLoading(false);
                  /*const currentPath = LocalStorageGetValue('currentPath');
                if(currentPath){
                  history.replace(currentPath);
                  window.history.replaceState(null, "", "/");
                }else{
                  history.replace('/dashboard');
                  LocalStorageSetValue('currentPath','/dashboard')
                  window.history.replaceState(null, "", "/");
                }*/
                })
                .catch((err) => {
                  signOut(auth).then(() => {
                    history.replace("/login");
                    LocalStorageClear();
                    window.history.replaceState(null, "", "/");
                  });
                });
            } else {
              history.replace("/login");
              LocalStorageClear();
              window.history.replaceState(null, "", "/");
            }
          });
        // return () => {
        //   unsubscribeFirebase();
        // };
      }
    }, []);
    return loading ? <>{loaderJSX}</> : <MainLayoutComponent {...props} />;
  };
}
