import { mergeRight } from "ramda";

const initialState = {
  provider: {},
  firebase: {},
  idToken: null,
  refreshToken: null,
  token: null,
  passwordChangeProviderDetails: {},
  agoraAppId: null,
};

export default function providerDetailsReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case "SAVEPROVIDERDETAILS":
      return {
        ...state,
        provider: payload,
      };
    case "UPDATEPROVIDERDETAILS":
      return {
        ...state,
        provider: mergeRight(state.provider, payload),
      };
    case "STOREFIREBASEDETAILS":
      return {
        ...state,
        firebase: payload,
      };
    case "STOREIDTOKEN": {
      return {
        ...state,
        idToken: payload,
      };
    }
    case "STOREREFRESHTOKEN": {
      return {
        ...state,
        refreshToken: payload,
      };
    }
    case "PASSWORDPROVIDERDETAILS": {
      return {
        ...state,
        passwordChangeProviderDetails: payload,
      };
    }
    case "AGORAAPPID": {
      return {
        ...state,
        agoraAppId: payload,
      };
    }
    case "STOREPROVIDERTOKEN": {
      return {
        ...state,
        token: payload,
      };
    }
    default:
      return state;
  }
}
