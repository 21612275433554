import axios from "axios";
import { toast } from "react-toastify";
import { LocalStorageClear, ClearCache } from "../Services/Auth";
import { browserDetails } from "../Services/Auth/browserDetails";
import { store } from "../index";
import {
  storeIdToken,
  storeRefreshToken,
} from "../Redux/Actions/providerDetailsAction";
import { getAuth, signOut } from "firebase/auth";

export const logouts = async (self) => {
  let browserName = browserDetails();
  let browserVersion = navigator.appVersion;
  const state = store.getState();
  try {
    const id =
      /*JSON.parse(LocalStorageGetValue("id"))*/ state?.providerDetailsReducer
        ?.provider?.id;
    const token =
      /*LocalStorageGetValue("token")*/ state?.providerDetailsReducer?.token;

    const auth = getAuth();

    await signOut(auth);

    const response = await axios.post(`/api/loginEndpoints/v1/logout/`, {
      id,
      token,
      browserName,
      browserVersion,
    });
    if (response.status === 200) {
      ClearCache();
      LocalStorageClear();
      sessionStorage.removeItem("currentPath");
      window.location.reload();
      //self.props.history.push("/login");
    }
  } catch (error) {
    self?.setState({ logoutLoader: false });
    toast.warn(error);
  }
};

export const continueAuthenticates = async (passingObject, self) => {
  const dispatch = store.dispatch;
  try {
    const response = await axios.post(
      `/api/loginEndpoints/v1/loginWithPassword`,
      passingObject
    );
    if (response.data.status === true) {
      //LocalStorageSetValue("firebaseToken", response.data.idToken);
      //LocalStorageSetValue("rToken", response.data.refreshToken);
      //LocalStorageSetValue("idToken", response.data.idToken);
      dispatch(storeRefreshToken(response.data.refreshToken));
      dispatch(storeIdToken(response.data.idToken));
      axios.defaults.headers.common["Authorization"] = response.data.idToken;
      self.setState({ authenticateModel: false });
      self.setState({ authenticatePassword: null });
    } else {
      if (response.data.errorId === 106) {
        LocalStorageClear();
        sessionStorage.removeItem("currentPath");
        self.setState({ lockedUserModel: true });
      }
      toast.warning(response.data.errorMessage);
      self.setState({ disabled: false });
    }
  } catch (error) {
    ClearCache();
    toast.warning(error);
    self.setState({ disabled: false });
  }
};
export const removeClickedSessionTo = (self) => {
  LocalStorageClear();
  self.setState({ sessionTimeOutModel: false });
  self.setState({ lockedUserModel: false });
  self.setState({ activeOnAnotherDevice: false });
  self.setState({ providerDenied: false });
  window.location.reload();
  //self.props.history.push("/login");
};

export const removeClickedSessionTO = (self) => {
  LocalStorageClear();
  self.setState({ sessionTimeOutModel: false });
  self.setState({ lockedUserModel: false });
  self.setState({ activeOnAnotherDevice: false });
  self.setState({ providerDenied: false });
  window.location.reload();
  //self.props.history.push("/login");
};

export const callFeedBackAPI = async (body) => {
  try {
    const { data } = await axios.post(
      "/api/providerEndpoints/v1/saveCallFeedback",
      body
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};
