const initialState = {
  unsupportedBrowser: false,
};

export default function unsupportedBrowserReducer(
  state = initialState,
  action
) {
  if (action.type) {
    return { ...state, unsupportedBrowser: true };
  }
  return state;
}
