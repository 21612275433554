import produce, { enableMapSet } from "immer";

enableMapSet();

const initialState = {
  breadCrumbsState: [],
};

const BreadCrumbReducer = produce((draft = initialState, action) => {
  switch (action.type) {
    case "ADDBREADCRUMB":
      if (
        draft.breadCrumbsState.every(
          (breadCrumbItem) => breadCrumbItem.url !== action.payload.url
        )
      ) {
        draft.breadCrumbsState.push(action.payload);
      }
      break;
    case "RESETBREADCRUMB":
      draft.breadCrumbsState = [];
      break;
    case "POPBREADCRUMBBYINDEX":
      draft.breadCrumbsState = draft.breadCrumbsState.slice(
        0,
        action.payload + 1
      );
      break;
    case "REMOVEBREADCRUMBBYURL": {
      const removeBreadCrumbIndex = draft.breadCrumbsState.findIndex(
        ({ url }) => action.payload === url
      );
      draft.breadCrumbsState.splice(removeBreadCrumbIndex, 1);
      break;
    }
    default:
      return draft;
  }
});

export default BreadCrumbReducer;
