import axios from "axios";
import { toast } from "react-toastify";
import { ClearCache, browserDetails } from "../Auth";

export const LoginFailedAPI = async (objectMake) => {
  try {
    const response = await axios.post(
      `/api/userEndpoints/v1/loginFailed`,
      objectMake,
      {
        params: {
          opSysType: "Website",
        },
      }
    );
    ClearCache();
    toast.warn(response.data.errorMessage);
  } catch (error) {
    toast.warn(error);
  }
};
export const LoginSuccessAPI = async (email) => {
  try {
    const response = await axios.post(
      `/api/userEndpoints/v1/getEmailByPhoneNumber`,
      {
        phoneNumber: email,
      }
    );
    return response.data;
  } catch (error) {
    ClearCache();
    toast.warn(error);
  }
};

export const LoginWithEmailAPI = async (objectMake) => {
  try {
    return await axios.post(`/api/loginEndpoints/v1/loginDetails`, objectMake);
  } catch (error) {
    ClearCache();
    //window.location.reload();
    toast.warn(error);
  }
};
export const AcceptTermsCondition = async (body) => {
  let browserName = browserDetails();
  try {
    return await axios.post(`/api/loginEndpoints/v1/acceptTermsAndCondition`, {
      ...body,
      browserName: browserName,
      browserVersion: navigator.appVersion,
      opSysType: "Website",
    });
  } catch (error) {
    toast.warn(error);
  }
};

export const LoginRefresh = (uid) =>
  axios.post("/api/loginEndpoints/v1/loginRefresh", {
    firebaseUid: uid,
  });
