import {
  useEffect,
  useState,
  useCallback,
  useRef,
  memo,
  useMemo,
  forwardRef,
  useImperativeHandle,
  PureComponent,
} from "react";
import styled from "@emotion/styled";
import MicIcon from "@material-ui/icons/Mic";
import MicOffIcon from "@material-ui/icons/MicOff";
import { Avatar, Typography } from "@material-ui/core";
import { ReactComponent as VideoOn } from "../../Assets/images/SVGS/Group 1171276208 (2).svg";
import { ReactComponent as VideoOff } from "../../Assets/images/SVGS/Group 1171276208 (3).svg";
import { ReactComponent as VolumeDown } from "../../Assets/images/SVGS/Volume Down.svg";
import { ReactComponent as VolumeOff } from "../../Assets/images/SVGS/Volume Off.svg";
import { ReactComponent as Disconnect } from "../../Assets/images/SVGS/Call 2.svg";
import { ReactComponent as MinimizeView } from "../../Assets/images/SVGS/Group 1171276194.svg";
import { ReactComponent as MaximizeView } from "../../Assets/images/SVGS/Group 1171276202 (2).svg";
import microphone from "../../Assets/images/microphone.svg";
import FlipCameraAndroidOutlinedIcon from "@material-ui/icons/FlipCameraAndroidOutlined";
import FlipCameraAndroidIcon from "@material-ui/icons/FlipCameraAndroid";
import VideocamOutlinedIcon from "@material-ui/icons/VideocamOutlined";
import MicNoneOutlinedIcon from "@material-ui/icons/MicNoneOutlined";
import MicOffOutlinedIcon from "@material-ui/icons/MicOffOutlined";
import VideocamOffOutlinedIcon from "@material-ui/icons/VideocamOffOutlined";
import moment from "moment";
import useFireBaseCallServices from "./FirebaseService";
import { useSelector, useDispatch } from "react-redux";
import VolumeUpOutlinedIcon from "@material-ui/icons/VolumeUpOutlined";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import VolumeOffOutlinedIcon from "@material-ui/icons/VolumeOffOutlined";
import CallEndIcon from "@material-ui/icons/CallEnd";
import "../AgoraCalls/AgoraCalls.scss";
import { useMediaQuery } from "@mui/material";
import AgoraRTC from "agora-rtc-sdk-ng";
import {
  showGroupCallmaximum,
  showGroupCallminimum,
} from "../../Redux/Actions/callActions";
import { toast } from "react-toastify";

const gridAreaArray = ["A", "B", "C", "D", "E", "F", "G", "H"];
const MobileMainContainer = styled.div`
  background: #9b51e0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  min-width: 0px;
  align-items: center;
  position: relative;
`;

const MobileMainRTCFeed = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: transparent;
`;

const ControlsMobileContainer = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
  column-gap: 12px;
  display: flex;
  z-index: 1;
`;

const IconButton = styled.div`
  font-size: 1.5rem;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #fff;
  background: ${(props) => (props.active ? "#000" : "transparent")};
`;

const AudioOnlyContainerMobile = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: #eff1f5;
  z-index: 1;
`;

const ControlsButton = styled.button`
  cursor: pointer;
  width: 64px;
  height: 72px;
  background: #56647d;
  border-radius: 16px;
  font-size: 0.75rem;
  line-height: 1.333;
  border: 0;
  color: #fff;
  display: flex;
  row-gap: 6px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  & > span {
    display: ${(props) => (props.hideLabel ? "none" : "inline")};
  }
`;

const ControlsContainer = styled.div({
  display: "none",
  position: "absolute",
  justifyContent: "center",
  columnGap: 10,
  width: "100%",
  bottom: 20,
  zIndex: 100,
  ".thirdParty-mainContainer:hover > &": {
    display: "flex",
  },
  "@media (hover: none) and (pointer: coarse)": {
    display: "flex",
  },
});

const MainCallWindowContainer = styled.div({
  background: "#172B4D",
  width: "100%",
  height: "100%",
  position: "relative",
  "&:hover .video-user-left-label": {
    display: "flex",
  },
});

const RemoteContainer = styled.div(({ remoteUserLength }) => ({
  display: "grid",
  height: "100%",
  ...(remoteUserLength === 1 && {
    gridTemplateAreas: '"A"',
  }),
  ...(remoteUserLength === 2 && {
    gridTemplateAreas: '"A B"',
  }),
  ...(remoteUserLength === 3 && {
    gridTemplateAreas: '"A B" "A C"',
  }),
  ...(remoteUserLength === 4 && {
    gridTemplateAreas: '"A B" "C D"',
  }),
  ...(remoteUserLength === 5 && {
    gridTemplateAreas: '"A A B B C C" "D D D E E E"',
  }),
  ...(remoteUserLength === 6 && {
    gridTemplateAreas: '"A A B B C C" "D D E E F F"',
  }),
  ...(remoteUserLength === 7 && {
    gridTemplateAreas: '"A A A B B B C C C D D D" "E E E E F F F F G G G G"',
  }),
  ...(remoteUserLength === 8 && {
    gridTemplateAreas: '"A B C D" "E F G H"',
  }),
}));

const LocalContainer = styled.div({
  position: "absolute",
  top: 8,
  right: 8,
  borderRadius: 10,
  // depends on resposive the container video width should change
  height: "30%",
  border: "1px solid #FFF",
  zIndex: 1,
  "& div": {
    borderRadius: 10,
  },
});

const RTCMobileLayout = styled.div({
  display: "flex",
  flexDirection: "row-reverse",
  overflowX: "scroll",
  columnGap: 10,
  alignSelf: "stretch",
  marginLeft: 10,
  marginRight: 10,
  zIndex: 1,
});

const RTCMobileContainer = styled.div({
  width: "25vw",
  aspectRatio: 1,
  border: "5px solid #FFF",
  position: "relative",
  flexShrink: 0,
});

const TransparentLayer = styled.div({
  background: "transparent",
  width: "100%",
  height: "100%",
  top: 0,
  position: "absolute",
  zIndex: 10,
});

const PlayerComponent = memo(
  forwardRef(({ videoTrack, audioTrack }, ref) => {
    const containerRef = useRef();
    useImperativeHandle(
      ref,
      () => {
        return {
          forceVideoPlay() {
            if (!videoTrack?.isPlaying) {
              videoTrack.play(containerRef.current);
            }
          },
          forceAudioPlay() {
            if (!audioTrack?.isPlaying) {
              audioTrack.play();
            }
          },
          forceVideoStop() {
            if (videoTrack?.isPlaying) {
              videoTrack.stop();
            }
          },
          forceAudioStop() {
            if (audioTrack.isPlaying) {
              audioTrack.stop();
            }
          },
        };
      },
      [videoTrack, audioTrack]
    );
    useEffect(() => {
      if (videoTrack) {
        videoTrack.play(containerRef.current);
      }
      if (audioTrack) {
        audioTrack.play();
      }
      return () => {
        if (videoTrack) {
          videoTrack.stop();
        }
        if (audioTrack) {
          audioTrack.stop();
        }
      };
    }, [videoTrack, audioTrack]);

    return (
      <div ref={containerRef} style={{ width: "100%", height: "100%" }}></div>
    );
  })
);

class TimerComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      secondsValue: 0,
    };
  }
  componentDidMount() {
    this.timerId = setInterval(() => {
      this.setState({
        secondsValue: moment().diff(moment(this.props.timeValue), "seconds"),
      });
    }, 1000);
  }
  render() {
    const { secondsValue } = this.state;
    return (
      <>{`${moment
        .duration(secondsValue, "seconds")
        .minutes()
        .toString()
        .padStart(2, "0")}:${moment
        .duration(secondsValue, "seconds")
        .seconds()
        .toString()
        .padStart(2, "0")}`}</>
    );
  }
  componentWillUnmount() {
    clearInterval(this.timerId);
  }
}

const useAgoraCall = (appid, channel, token, uid) => {
  const [AgoraClient] = useState(() =>
    AgoraRTC.createClient({ codec: "h264", mode: "rtc" })
  );
  const [remoteUsersList, setRemoteUsersList] = useState(new Set());
  const [remoteTracks, setRemoteTracks] = useState(new Map());
  const [localVideoTrack, setLocalVideoTrack] = useState();
  const [localAudioTrack, setLocalAudioTrack] = useState();
  const [connectionState, setConnectionState] = useState("");
  const [localVideoOn, setLocalVideoOn] = useState(false);
  const [localAudioOn, setLocalAudioOn] = useState(false);
  const [facingMode, setFacingMode] = useState("user");
  const [timerData, setTimerData] = useState(new Map());
  const dispatch = useDispatch();

  const onChangeLocalAudioIntialAudioOn = () => {
    localAudioTrack.setEnabled(true).then(() => {
      setLocalAudioOn(true);
    });
  };

  const onChangeLocalAudio = useCallback(() => {
    if (localAudioTrack?.enabled) {
      localAudioTrack.setEnabled(false).then(() => {
        setLocalAudioOn(false);
      });
    } else {
      localAudioTrack.setEnabled(true).then(() => {
        setLocalAudioOn(true);
      });
    }
  }, [localAudioTrack]);

  const onChangeLocalVideo = useCallback(() => {
    if (localVideoTrack?.enabled) {
      localVideoTrack.setEnabled(false).then(() => {
        setLocalVideoOn(false);
      });
    } else {
      localVideoTrack.setEnabled(true).then(() => {
        setLocalVideoOn(true);
      });
    }
  }, [localVideoTrack]);

  const onChangeCamera = useCallback(() => {
    localVideoTrack
      .setDevice({ facingMode: facingMode === "user" ? "environment" : "user" })
      .then(() => {
        setFacingMode(facingMode === "user" ? "environment" : "user");
      });
  }, [localVideoTrack, facingMode]);

  const userJoinedEventCallback = useCallback((user) => {
    setRemoteUsersList((remoteUsersList) => {
      const newRemoteUsersList = new Set(remoteUsersList);
      newRemoteUsersList.add(user);
      return newRemoteUsersList;
    });
    setTimerData((timerData) => {
      const newTimerData = new Map(timerData);
      newTimerData.set(user.uid, new Date().getTime());
      return newTimerData;
    });
  }, []);

  const userLeftEventCallback = useCallback((user) => {
    setRemoteUsersList((remoteUsersList) => {
      const newRemoteUsersList = new Set(remoteUsersList);
      newRemoteUsersList.delete(user);
      return newRemoteUsersList;
    });
    setTimerData((timerData) => {
      const newTimerData = new Map(timerData);
      newTimerData.delete(user.uid);
      return newTimerData;
    });
  }, []);

  const userPublishedEventCallback = useCallback((user, mediaType) => {
    AgoraClient.subscribe(user, mediaType).then(() => {
      setRemoteTracks((remoteTracks) => {
        const newRemoteTracks = new Map(remoteTracks);
        if (newRemoteTracks.has(user)) {
          const tracksData = newRemoteTracks.get(user);
          if (mediaType === "audio") {
            tracksData.set("audio", user.audioTrack);
          } else if (mediaType === "video") {
            tracksData.set("video", user.videoTrack);
          }
        } else {
          newRemoteTracks.set(user, new Map());
          const tracksData = newRemoteTracks.get(user);
          if (mediaType === "audio") {
            tracksData.set("audio", user.audioTrack);
          } else if (mediaType === "video") {
            tracksData.set("video", user.videoTrack);
          }
        }
        return newRemoteTracks;
      });
    });
  }, []);

  const userUnPublishedEventCallback = useCallback((user, mediaType) => {
    AgoraClient.unsubscribe(user, mediaType).then(() => {
      setRemoteTracks((remoteTracks) => {
        const newRemoteTracks = new Map(remoteTracks);
        const trackData = newRemoteTracks.get(user);
        trackData.delete(mediaType);
        if (!trackData.size) {
          newRemoteTracks.delete(user);
        }
        return newRemoteTracks;
      });
    });
  }, []);

  const connectionStateEventCallback = useCallback((curState, prevState) => {
    setConnectionState(curState);
  }, []);

  useEffect(() => {
    let localAudioTrack, localVideoTrack;
    (async () => {
      try {
        await AgoraClient.join(appid, String(channel), token, uid);
        [localAudioTrack, localVideoTrack] =
          await AgoraRTC.createMicrophoneAndCameraTracks();
        localAudioTrack.setEnabled(false);
        localVideoTrack.setEnabled(false);
        setLocalAudioTrack(localAudioTrack);
        setLocalVideoTrack(localVideoTrack);
        await AgoraClient.publish([localAudioTrack, localVideoTrack]);
      } catch (err) {
        console.log(err);
      }
    })();
    return () => {
      localAudioTrack?.close?.();
      localVideoTrack?.close?.();
      AgoraClient?.leave?.();
    };
  }, []);
  useEffect(() => {
    AgoraClient.on("user-joined", userJoinedEventCallback);
    AgoraClient.on("user-left", userLeftEventCallback);
    AgoraClient.on("user-published", userPublishedEventCallback);
    AgoraClient.on("user-unpublished", userUnPublishedEventCallback);
    AgoraClient.on("connection-state-change", connectionStateEventCallback);
    return () => {
      AgoraClient.off("user-joined", userJoinedEventCallback);
      AgoraClient.off("user-left", userLeftEventCallback);
      AgoraClient.off("user-published", userPublishedEventCallback);
      AgoraClient.off("user-unpublished", userUnPublishedEventCallback);
      AgoraClient.off("connection-state-change", connectionStateEventCallback);
    };
  }, []);

  return useMemo(
    () => ({
      remoteUsersList,
      remoteTracks,
      connectionState,
      localAudioOn,
      localVideoOn,
      onChangeLocalAudio,
      onChangeLocalAudioIntialAudioOn,
      onChangeLocalVideo,
      localVideoTrack,
      localAudioTrack,
      onChangeCamera,
      facingMode,
      timerData,
    }),
    [
      facingMode,
      remoteUsersList,
      remoteTracks,
      connectionState,
      localAudioOn,
      localVideoOn,
      onChangeLocalAudio,
      onChangeLocalAudioIntialAudioOn,
      onChangeCamera,
      onChangeLocalVideo,
      localVideoTrack,
      localAudioTrack,
      timerData,
    ]
  );
};

const ThirdPartyCall = ({
  channel,
  token,
  appid,
  uid,
  onDisconnectCall,
  initializeConfig,
  callUserDetails,
  role,
  firebaseUid,
  startCallAudit,
  stopCallAudit,
  auditId,
  handleCallReject,
  onCallWindowClose,
  handleCallNotAnswer,
}) => {
  //const agoraEngine = useRTCClient(AgoraRTC.createClient({ codec: "h264", mode: "rtc" }));
  /*const [AgoraClient] = useState(() =>
    AgoraRTC.createClient({ codec: "h264", mode: "rtc" })
  );
  const [remoteUsersList, setRemoteUsersList] = useState(new Set());
  const [remoteTracks, setRemoteTracks] = useState(new Map());
  const [connectionState, setConnectionState] = useState("");
  const [localVideoTrack, setLocalVideoTrack] = useState();
  const [localAudioTrack, setLocalAudioTrack] = useState();
  const [localVideoOn, setLocalVideoOn] = useState(() => {
    if (initializeConfig) {
      return initializeConfig.video;
    }
    return false;
  });
  const [localAudioOn, setLocalAudioOn] = useState(() => {
    if (initializeConfig) {
      return initializeConfig.audio;
    }
    return false;
  });

  const onChangeLocalAudio = () => {
    if (localAudioTrack?.enabled) {
      localAudioTrack.setEnabled(false).then(() => {
        setLocalAudioOn(false);
      });
    } else {
      localAudioTrack.setEnabled(true).then(() => {
        setLocalAudioOn(true);
      });
    }
  };

  const onChangeLocalVideo = () => {
    if (localVideoTrack?.enabled) {
      localVideoTrack.setEnabled(false).then(() => {
        setLocalVideoOn(false);
      });
    } else {
      localVideoTrack.setEnabled(true).then(() => {
        setLocalVideoOn(true);
      });
    }
  };

  const userJoinedEventCallback = useCallback((user) => {
    setRemoteUsersList((remoteUsersList) => {
      const newRemoteUsersList = new Set(remoteUsersList);
      newRemoteUsersList.add(user);
      return newRemoteUsersList;
    });
  }, []);

  const userLeftEventCallback = useCallback((user) => {
    setRemoteUsersList((remoteUsersList) => {
      const newRemoteUsersList = new Set(remoteUsersList);
      newRemoteUsersList.delete(user);
      return newRemoteUsersList;
    });
  }, []);

  const userPublishedEventCallback = useCallback((user, mediaType) => {
    AgoraClient.subscribe(user, mediaType).then(() => {
      setRemoteTracks((remoteTracks) => {
        const newRemoteTracks = new Map(remoteTracks);
        if (newRemoteTracks.has(user)) {
          const tracksData = newRemoteTracks.get(user);
          if (mediaType === "audio") {
            tracksData.set("audio", user.audioTrack);
          } else if (mediaType === "video") {
            tracksData.set("video", user.videoTrack);
          }
        } else {
          newRemoteTracks.set(user, new Map());
          const tracksData = newRemoteTracks.get(user);
          if (mediaType === "audio") {
            tracksData.set("audio", user.audioTrack);
          } else if (mediaType === "video") {
            tracksData.set("video", user.videoTrack);
          }
        }
        return newRemoteTracks;
      });
    });
  }, []);

  const userUnPublishedEventCallback = useCallback((user, mediaType) => {
    AgoraClient.unsubscribe(user, mediaType).then(() => {
      setRemoteTracks((remoteTracks) => {
        const newRemoteTracks = new Map(remoteTracks);
        const trackData = newRemoteTracks.get(user);
        trackData.delete(mediaType);
        if (!trackData.size) {
          newRemoteTracks.delete(user);
        }
        return newRemoteTracks;
      });
    });
  }, []);

  const connectionStateEventCallback = useCallback((curState, prevState) => {
    setConnectionState(curState);
  }, []);

  const disconnectCall = () => {
    localVideoTrack.close();
    localAudioTrack.close();

    AgoraClient.off("user-joined", userJoinedEventCallback);
    AgoraClient.off("user-left", userLeftEventCallback);
    AgoraClient.off("user-published", userPublishedEventCallback);
    AgoraClient.off("user-unpublished", userUnPublishedEventCallback);
    AgoraClient.leave();
  };

  useEffect(() => {
    let localAudioTrack, localVideoTrack;
    (async () => {
      await AgoraClient.join(appid, String(channel), token, uid);
      [localAudioTrack, localVideoTrack] =
        await AgoraRTC.createMicrophoneAndCameraTracks();
      if (!initializeConfig?.audio) {
        localAudioTrack.setEnabled(false);
      }
      if (!initializeConfig?.video) {
        localVideoTrack.setEnabled(false);
      }
      setLocalAudioTrack(localAudioTrack);
      setLocalVideoTrack(localVideoTrack);
      await AgoraClient.publish([localAudioTrack, localVideoTrack]);
    })();
    return () => {
      localAudioTrack.close();
      localVideoTrack.close();
      AgoraClient.leave();
    };
  }, []);

  useEffect(() => {
    AgoraClient.on("user-joined", userJoinedEventCallback);
    AgoraClient.on("user-left", userLeftEventCallback);
    AgoraClient.on("user-published", userPublishedEventCallback);
    AgoraClient.on("user-unpublished", userUnPublishedEventCallback);
    AgoraClient.on("connection-state-change", connectionStateEventCallback);
    return () => {
      AgoraClient.off("user-joined", userJoinedEventCallback);
      AgoraClient.off("user-left", userLeftEventCallback);
      AgoraClient.off("user-published", userPublishedEventCallback);
      AgoraClient.off("user-unpublished", userUnPublishedEventCallback);
      AgoraClient.off("connection-state-change", connectionStateEventCallback);
    };
  }, []);*/
  const isSmallScreen = useMediaQuery("(max-width:950px)");
  const [isMobileBrowser, setIsMobileBrowser] = useState(false);
  const isMobileDevice = useMemo(() => {
    return window.matchMedia("not all and (hover:hover)")?.matches;
  }, []);
  const {
    remoteUsersList,
    remoteTracks,
    connectionState,
    localAudioOn,
    localVideoOn,
    localVideoTrack,
    localAudioTrack,
    onChangeLocalAudio,
    onChangeLocalAudioIntialAudioOn,
    onChangeLocalVideo,
    facingMode,
    onChangeCamera,
    timerData,
  } = useAgoraCall(appid, channel, token, uid);

  useFireBaseCallServices({
    role,
    agoraCallDetails: { channel, auditId },
    handleCallReject,
    handleCallNotAnswer,
    firebaseUid,
  });

  const dispatch = useDispatch();
  const [onMounttFirst, setOnmountFirst] = useState(true);
  const [mobileMainContainerUser, setMobileMainContainerUser] = useState();

  const callAuditState = useRef(null);

  const [maximizedWindow, minimizedWindow] = useSelector(
    ({ CallMinMax: { showGroupCallMini, showGroupCallMaxi } }) => [
      showGroupCallMaxi,
      showGroupCallMini,
    ]
  );

  // useEffect(() => {
  //   // Update state based on the result of the media query
  //   setIsMobileOrTablet(!isLargeScreen);
  // }, [isLargeScreen]);

  useEffect(() => {
    // Detect mobile browsers based on the user agent
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Check for Android
    if (/android/i.test(userAgent)) {
      setIsMobileBrowser(true);
    }
    // Check for iOS
    else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      setIsMobileBrowser(true);
    } else {
      setIsMobileBrowser(false);
    }
  }, []);

  useEffect(() => {
    if (!mobileMainContainerUser) {
      if (remoteUsersList.size) {
        const remoteUsersListArray = Array.from(remoteUsersList);
        setMobileMainContainerUser(remoteUsersListArray.at(-1));
      }
    }
  }, [remoteUsersList]);

  function onChangeMobileMainContainerUser(user) {
    setMobileMainContainerUser(user);
  }

  useEffect(() => {
    if (initializeConfig?.video && localVideoTrack) {
      onChangeLocalVideo();
    }
  }, [initializeConfig?.video, localVideoTrack]);

  // useEffect(() => {
  //   if (initializeConfig?.audio && localAudioTrack) {
  //     onChangeLocalAudio();
  //   }
  // }, [initializeConfig?.audio, localAudioTrack]);
  useEffect(() => {
    if (initializeConfig?.audio && localAudioTrack && onMounttFirst) {
      onChangeLocalAudioIntialAudioOn();
      setOnmountFirst(false);
    }
  }, [initializeConfig?.audio, localAudioTrack]);

  const handleMinimize = () => {
    dispatch(showGroupCallminimum());
  };

  const handleMaximize = () => {
    dispatch(showGroupCallmaximum());
  };

  useEffect(() => {
    if (connectionState === "CONNECTED") {
      startCallAudit().then(() => {
        callAuditState.current = "started";
      });
    }
  }, [connectionState]);

  useEffect(
    () => () => {
      if (callAuditState.current === "started") {
        stopCallAudit();
        callAuditState.current = null;
      }
      onCallWindowClose?.();
    },
    []
  );

  return (
    <>
      {/* Mobile View and controller (Condition)  */}
      {isMobileBrowser && remoteUsersList.size > 2 ? (
        <MobileMainContainer>
          <MobileMainRTCFeed>
            {mobileMainContainerUser &&
              (mobileMainContainerUser === "local" ? (
                <PlayerComponent key={"local"} videoTrack={localVideoTrack} />
              ) : (
                <PlayerComponent
                  key={mobileMainContainerUser.uid}
                  audioTrack={remoteTracks
                    .get(mobileMainContainerUser)
                    ?.get("audio")}
                  videoTrack={remoteTracks
                    .get(mobileMainContainerUser)
                    ?.get("video")}
                />
              ))}
            {mobileMainContainerUser &&
              (mobileMainContainerUser === "local"
                ? !localVideoOn
                : !remoteTracks.get(mobileMainContainerUser)?.has("video")) && (
                <AudioOnlyContainerMobile
                  style={{ background: "#9B51E0", top: 0 }}
                ></AudioOnlyContainerMobile>
              )}
          </MobileMainRTCFeed>
          <RTCMobileLayout
            style={{ ...(!remoteUsersList.size && { display: "none" }) }}
          >
            <RTCMobileContainer
              key="local"
              style={{ border: "none" }}
              onClick={() => onChangeMobileMainContainerUser("local")}
            >
              {!localVideoOn && (
                <AudioOnlyContainerMobile></AudioOnlyContainerMobile>
              )}
              {!(mobileMainContainerUser === "local") && (
                <PlayerComponent videoTrack={localVideoTrack} />
              )}
              {mobileMainContainerUser === "local" && <TransparentLayer />}
            </RTCMobileContainer>
            {Array.from(remoteUsersList).map((remoteUser) => (
              <RTCMobileContainer
                key={remoteUser.uid}
                onClick={() => onChangeMobileMainContainerUser(remoteUser)}
              >
                {!remoteTracks.get(remoteUser)?.has("video") && (
                  <AudioOnlyContainerMobile></AudioOnlyContainerMobile>
                )}
                {!(mobileMainContainerUser?.uid === remoteUser.uid) && (
                  <PlayerComponent
                    audioTrack={remoteTracks.get(remoteUser)?.get("audio")}
                    videoTrack={remoteTracks.get(remoteUser)?.get("video")}
                  />
                )}
                {mobileMainContainerUser?.uid === remoteUser.uid && (
                  <TransparentLayer />
                )}
              </RTCMobileContainer>
            ))}
          </RTCMobileLayout>
          <ControlsMobileContainer>
            {/* <ControlsContainer> */}
            <ControlsButton
              onClick={onChangeLocalVideo}
              hideLabel={minimizedWindow}
            >
              {localVideoOn && (
                <>
                  <VideoOn />
                  <span>Video On</span>
                </>
              )}
              {!localVideoOn && (
                <>
                  <VideoOff />
                  <span>Video Off</span>
                </>
              )}
            </ControlsButton>
            <ControlsButton onClick={onChangeLocalAudio}>
              {localAudioOn && (
                <>
                  <VolumeDown />
                  <span>Mic On</span>
                </>
              )}
              {!localAudioOn && (
                <>
                  <VolumeOff />
                  <span>Mic Off</span>
                </>
              )}
            </ControlsButton>
            <ControlsButton
              disabled={role === "thirdParty"}
              onClick={() => {
                if (maximizedWindow) {
                  handleMinimize();
                } else if (minimizedWindow) {
                  handleMaximize();
                }
              }}
            >
              {maximizedWindow && (
                <>
                  <MaximizeView />
                  <span>Minimize</span>
                </>
              )}
              {minimizedWindow && (
                <>
                  <MinimizeView />
                  <span>Maximize</span>
                </>
              )}
            </ControlsButton>
            <ControlsButton
              style={{ background: "#E52727" }}
              onClick={onDisconnectCall}
            >
              <Disconnect />
              <span>Disconnect</span>
            </ControlsButton>
            {isSmallScreen && isMobileBrowser && (
              <ControlsButton onClick={onChangeCamera}>
                <FlipCameraAndroidIcon />
                <span>Switch</span>
              </ControlsButton>
            )}
            {/* </ControlsContainer> */}
            {/* <IconButton active={!localVideoOn} onClick={onChangeLocalVideo}>
              {localVideoOn ? (
                <VideocamOutlinedIcon />
              ) : (
                <VideocamOffOutlinedIcon />
              )}
            </IconButton>
            <IconButton active={!localAudioOn} onClick={onChangeLocalAudio}>
              {localAudioOn ? <MicNoneOutlinedIcon /> : <MicOffOutlinedIcon />}
            </IconButton>
            <IconButton style={{ background: "#E52727" }}>
              <CallEndIcon />
            </IconButton>
            <IconButton disabled style={{ background: "#D1D6DD66" }}>
              <VolumeUpIcon />
            </IconButton>
            <IconButton
              active={facingMode === "environment"}
              onClick={onChangeCamera}
            >
              <FlipCameraAndroidIcon />
            </IconButton> */}
          </ControlsMobileContainer>
        </MobileMainContainer>
      ) : (
        <MainCallWindowContainer className="thirdParty-mainContainer">
          {connectionState === "CONNECTED" ? (
            <>
              {(() => {
                const localUserDetails = callUserDetails?.[uid];
                return (
                  <LocalContainer
                    style={{
                      ...(!localVideoOn &&
                        !localAudioOn && { border: "none", display: "none" }),
                      width: isSmallScreen ? "37%" : "15%",
                      right: isSmallScreen ? 8 : 15,
                    }}
                  >
                    {!localVideoOn && localAudioOn && (
                      <div
                        style={{
                          display: "grid",
                          placeItems: "center",
                          placeContent: "center",
                          position: "absolute",
                          top: 0,
                          background: "#172B4D",
                          width: "100%",
                          height: "100%",
                          zIndex: 1,
                        }}
                      >
                        <Avatar
                          style={{
                            background: "#FFF",
                            height: "52px",
                            width: "52px",
                            display: "flex",
                          }}
                          src={localUserDetails?.profilePicUrl}
                        >
                          <Typography className="content-emphasized-label">
                            {role === "thirdParty"
                              ? (() => {
                                  let seperatorIndex =
                                    localUserDetails?.guestName.search(
                                      /( |-|_)/
                                    );
                                  seperatorIndex += 1;
                                  if (!seperatorIndex) {
                                    seperatorIndex = localUserDetails?.guestName
                                      .slice(1)
                                      .search(/[A-Z]/);
                                    if (seperatorIndex === -1) {
                                      seperatorIndex =
                                        localUserDetails?.guestName.length - 1;
                                    }
                                  }
                                  return (
                                    localUserDetails?.guestName[0].toUpperCase() +
                                    localUserDetails?.guestName[
                                      seperatorIndex
                                    ].toUpperCase()
                                  );
                                })()
                              : localUserDetails.fname
                                  .replace(/[\s'.-]/g, "")
                                  .charAt(0) +
                                localUserDetails.lname
                                  .replace(/[\s'.-]/g, "")
                                  .charAt(0)}
                          </Typography>
                        </Avatar>
                        <div
                          className="user-detail-label-local"
                          style={{ width: "100%", minWidth: 0 }}
                        >
                          <Typography
                            className="content-emphasized-text-button"
                            style={{
                              textAlign: "center",
                              width: "100%",
                              display: "flex",
                              flexWrap: "wrap",
                              justifyContent: "center",
                            }}
                          >
                            <span
                              style={{
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                wordBreak: "break-all",
                                maxWidth: "95%",
                              }}
                            >
                              {role === "thirdParty"
                                ? localUserDetails?.guestName
                                : localUserDetails?.name}
                            </span>
                            {localUserDetails?.remoteProviderType && (
                              <>
                                <span>{`, `}</span>
                                <span
                                  style={{
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                    wordBreak: "break-all",
                                  }}
                                >{`${
                                  localUserDetails?.displayName ||
                                  localUserDetails?.remoteProviderType
                                }`}</span>
                              </>
                            )}
                          </Typography>
                          <Typography
                            className="captions-sub-default"
                            style={{
                              color: "#FFF",
                              textAlign: "center",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              wordBreak: "break-all",
                            }}
                          >
                            {localUserDetails?.role === "BD"
                              ? localUserDetails?.displayName ||
                                "Bedside Clinician"
                              : localUserDetails?.role === "RD"
                              ? "Remote Clinician"
                              : role === "thirdParty" && "Guest"}
                          </Typography>
                        </div>
                      </div>
                    )}
                    <PlayerComponent videoTrack={localVideoTrack} />
                  </LocalContainer>
                );
              })()}
              <RemoteContainer remoteUserLength={remoteUsersList.size}>
                {[...remoteUsersList].map((remoteUser, i) => {
                  const remoteUserDetails = callUserDetails[remoteUser.uid];
                  return (
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        gridArea: gridAreaArray[i],
                      }}
                      key={remoteUser.uid}
                    >
                      <PlayerComponent
                        videoTrack={remoteTracks?.get(remoteUser)?.get("video")}
                        audioTrack={remoteTracks?.get(remoteUser)?.get("audio")}
                      />
                      <>
                        {remoteTracks?.get(remoteUser)?.has("video") ? (
                          <div
                            className="video-user-left-label"
                            style={{ boxSizing: "content-box" }}
                          >
                            <>
                              <Typography
                                className="content-emphasized-text-button"
                                style={{
                                  textAlign: "start",
                                  width: "100%",
                                  display: "flex",
                                  flexWrap: "wrap",
                                }}
                              >
                                <span
                                  style={{
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                    wordBreak: "break-all",
                                    maxWidth: "95%",
                                  }}
                                >
                                  {remoteUserDetails?.name}
                                </span>
                                {remoteUserDetails?.remoteProviderType && (
                                  <span>{`, ${remoteUserDetails?.remoteProviderType}`}</span>
                                )}
                              </Typography>
                              <Typography
                                className="captions-sub-default"
                                style={{
                                  color: "white",
                                  textAlign: "start",
                                  overflowWrap: "break-word",
                                }}
                              >
                                {remoteUserDetails?.role === "GUEST"
                                  ? "Guest"
                                  : remoteUserDetails?.role === "BD"
                                  ? remoteUserDetails?.displayName ||
                                    "Bedside Clinician"
                                  : "Remote Clinician"}
                              </Typography>
                              <Typography
                                className="captions-sub-default"
                                style={{
                                  color: "white",
                                  paddingTop: "3px",
                                }}
                              >
                                <TimerComponent
                                  timeValue={timerData.get(remoteUser?.uid)}
                                />
                                {!remoteTracks
                                  ?.get(remoteUser)
                                  ?.has("audio") && (
                                  <img
                                    height="15px"
                                    width="15px"
                                    alt="Name"
                                    src={microphone}
                                    style={{ verticalAlign: "sub" }}
                                  />
                                )}
                              </Typography>
                            </>
                          </div>
                        ) : (
                          <div className="align-center" style={{ top: 0 }}>
                            <>
                              <Typography
                                className="captions-sub-default"
                                style={{ color: "white", marginBottom: 10 }}
                              >
                                <TimerComponent
                                  timeValue={timerData.get(remoteUser?.uid)}
                                />
                              </Typography>
                              <Avatar
                                src={remoteUserDetails?.profilePicUrl}
                                style={{
                                  ...{
                                    background: "white",
                                    height: "80px",
                                    width: "80px",
                                    display: "flex",
                                  },
                                }}
                              >
                                <Typography className="content-emphasized-label">
                                  {remoteUserDetails.fname
                                    .replace(/[\s'.-]/g, "")
                                    .charAt(0) +
                                    remoteUserDetails.lname
                                      .replace(/[\s'.-]/g, "")
                                      .charAt(0)}
                                </Typography>
                              </Avatar>
                              <div
                                className="user-detail-label"
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  flexDirection: "column",
                                }}
                              >
                                <Typography
                                  className="content-emphasized-text-button"
                                  style={{
                                    textAlign: "start",
                                    width: "100%",
                                    display: "flex",
                                    flexWrap: "wrap",
                                    justifyContent: "center",
                                    overflowWrap: "break-word",
                                  }}
                                >
                                  <span
                                    style={{
                                      whiteSpace: "nowrap",
                                      textOverflow: "ellipsis",
                                      overflow: "hidden",
                                      wordBreak: "break-all",
                                      maxWidth: "95%",
                                    }}
                                  >
                                    {remoteUserDetails?.name}
                                  </span>
                                  {remoteUserDetails?.remoteProviderType && (
                                    <span>{`, ${remoteUserDetails?.remoteProviderType}`}</span>
                                  )}
                                </Typography>
                                <Typography
                                  className="captions-sub-default"
                                  style={{
                                    color: "white",
                                    overflowWrap: "break-word",
                                    textAlign: "center",
                                  }}
                                >
                                  {remoteUserDetails?.role === "GUEST"
                                    ? "Guest"
                                    : remoteUserDetails?.role === "BD"
                                    ? remoteUserDetails?.displayName ||
                                      "Bedside Clinician"
                                    : "Remote Clinician"}
                                  {!remoteTracks
                                    ?.get(remoteUser)
                                    ?.has("audio") && (
                                    <img
                                      height="15px"
                                      width="15px"
                                      alt="Name"
                                      src={microphone}
                                      style={{ verticalAlign: "sub" }}
                                    />
                                  )}
                                </Typography>
                              </div>
                            </>
                          </div>
                        )}
                      </>
                    </div>
                  );
                })}
              </RemoteContainer>
              <ControlsContainer>
                <ControlsButton
                  onClick={onChangeLocalVideo}
                  hideLabel={minimizedWindow}
                >
                  {localVideoOn && (
                    <>
                      <VideoOn />
                      <span>Video On</span>
                    </>
                  )}
                  {!localVideoOn && (
                    <>
                      <VideoOff />
                      <span>Video Off</span>
                    </>
                  )}
                </ControlsButton>
                <ControlsButton onClick={onChangeLocalAudio}>
                  {localAudioOn && (
                    <>
                      <VolumeDown />
                      <span>Mic On</span>
                    </>
                  )}
                  {!localAudioOn && (
                    <>
                      <VolumeOff />
                      <span>Mic Off</span>
                    </>
                  )}
                </ControlsButton>
                <ControlsButton
                  disabled={role === "thirdParty"}
                  onClick={() => {
                    if (maximizedWindow) {
                      handleMinimize();
                    } else if (minimizedWindow) {
                      handleMaximize();
                    }
                  }}
                >
                  {maximizedWindow && (
                    <>
                      <MaximizeView />
                      <span>Minimize</span>
                    </>
                  )}
                  {minimizedWindow && (
                    <>
                      <MinimizeView />
                      <span>Maximize</span>
                    </>
                  )}
                </ControlsButton>
                <ControlsButton
                  style={{ background: "#E52727" }}
                  onClick={onDisconnectCall}
                >
                  <Disconnect />
                  <span>Disconnect</span>
                </ControlsButton>
                {isSmallScreen && isMobileBrowser && (
                  <ControlsButton onClick={onChangeCamera}>
                    <FlipCameraAndroidIcon />
                    <span>Switch</span>
                  </ControlsButton>
                )}
              </ControlsContainer>
            </>
          ) : (
            <div style={{ placeSelf: "center" }}>{connectionState}</div>
          )}
        </MainCallWindowContainer>
      )}
    </>
  );
};

export default memo(ThirdPartyCall);
