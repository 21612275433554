import {
  getAgoraToken,
  checkingProviderStatus,
} from "../../Services/AudVidCalls/AudVidCalls";
import { groupCallDisconnect } from "../../Services/GroupCall/GroupCall";
import { getThirdPartyCallData } from "../../Services/ThirdPartyCall";
import { providerAvailability } from "../../Redux/Actions/providerStatusAction";
import {
  showmodel,
  showMessage,
  showGroupModel,
  openThirdPartyCall,
} from "../../Redux/Actions/callActions";
import axios from "axios";
import { toast } from "react-toastify";
import { store } from "../../index";

function filterfunction(
  selected,
  value,
  providerListCopy,
  selectedfilter1,
  selectedtype,
  selectedstatus,
  selectedhospital,
  selectedlocation
) {
  let filterValue = providerListCopy;

  if (selectedtype && selectedfilter1 !== "Type") {
    filterValue = filterValue.filter(
      (s) => s.remoteProviderType === selectedtype
    );
  } else if (selected && selectedfilter1 === "Type") {
    filterValue = filterValue.filter(
      (s) => s.remoteProviderType === value.type
    );
  }

  if (selectedstatus && selectedfilter1 !== "Status") {
    if (selectedstatus === "Active") {
      filterValue = filterValue.filter((s) => s.status === selectedstatus);
    }
    if (selectedstatus === "OffLine") {
      filterValue = filterValue.filter(
        (s) =>
          s.status === "OffLine" ||
          s.status === "Approved" ||
          s.status === "AutoLock"
      );
    }
  } else if (selectedfilter1 === "Status") {
    if (selected === "Active") {
      filterValue = filterValue.filter((s) => s.status === selected);
    }
    if (selected === "OffLine") {
      filterValue = filterValue.filter(
        (s) =>
          s.status === "OffLine" ||
          s.status === "Approved" ||
          s.status === "AutoLock"
      );
    }
  }

  if (selectedhospital && selectedfilter1 !== "Hospital") {
    filterValue = filterValue.filter((s) => s.hospital === selectedhospital);
  } else if (selectedfilter1 === "Hospital") {
    filterValue = filterValue.filter((s) => s.hospital === value.name);
  }

  if (selectedlocation && selectedfilter1 !== "Location") {
    filterValue = filterValue.filter(
      (s) => s.subRegionName === selectedlocation
    );
  } else if (selectedfilter1 === "Location") {
    filterValue = filterValue.filter(
      (s) => s.subRegionName === value.subRegionName
    );
  }
  return filterValue;
}

function initiateCallService(e, receiver, typeofCall, dispatch, patientid) {
  dispatch(showMessage("reset", false, ""));
  const state = store.getState();
  let id =
    /*JSON.parse(LocalStorageGetValue("id"))*/ state?.providerDetailsReducer
      ?.provider?.id;
  let channelName = id + "-" + receiver.id;
  let channelDetails = { channelName, uid: JSON.parse(id) };
  let testToken =
    /*LocalStorageGetValue("token")*/ state?.providerDetailsReducer?.token;
  let profilePicUrl = receiver["profilePicUrl"];
  let twoLetters =
    receiver.fname.replace(/[\s'.-]/g, "")[0].toUpperCase() +
    receiver.lname.replace(/[\s'.-]/g, "")[0].toUpperCase();
  //need to change after db cleanup
  let hospital = receiver.remoteProviderType
    ? "Remote Clinician"
    : receiver.displayName || "Bedside Clinician";
  let roleCall =
    receiver.remoteProviderType === undefined
      ? ""
      : receiver.displayName || receiver.remoteProviderType;
  let mergeName = receiver.fname + " " + receiver.lname;
  let name = mergeName.length > 23 ? mergeName.slice(0, 23) + "..." : mergeName;
  if (receiver.providerName) {
    name =
      receiver.providerName.length > 23
        ? receiver.providerName.slice(0, 23) + "..."
        : receiver.providerName;
  }
  let userDetails =
    /*JSON.parse(LocalStorageGetValue("userDetails"))*/ state
      ?.providerDetailsReducer?.provider;
  let callInitiaterFirst = userDetails["fname"];
  let callInitiaterLast = userDetails["lname"];
  let callInitPicture = userDetails["profilePicUrl"];
  let callInitiaterTwoLetters =
    callInitiaterFirst.replace(/[\s'.-]/g, "")[0].toUpperCase() +
    callInitiaterLast.replace(/[\s'.-]/g, "")[0].toUpperCase();
  let callInitiaterName =
    userDetails["name"].length > 10
      ? userDetails["name"].slice(0, 10) + "..."
      : userDetails["name"];
  let initRole =
    userDetails["role"] !== "BD"
      ? "," + (userDetails["displayName"] || userDetails["remoteProviderType"])
      : "";
  callInitiaterName = callInitiaterName + initRole;
  let callInitiaterRole =
    userDetails["remoteProviderType"] === undefined
      ? ""
      : userDetails["displayName"] || userDetails["remoteProviderType"];
  //need to change after db cleanup
  /*let callInitiaterHospital =
    userDetails["hospital"] === "Remote Clinician" ||
    userDetails["hospital"] === "Remote Side Provider"
      ? "Remote Clinician"
      : "Bedside Clinician";*/
  let callInitiaterHospital =
    userDetails["role"] === "RD"
      ? "Remote Clinician"
      : userDetails["role"] === "BD"
      ? userDetails["displayName"] || "Bedside Clinician"
      : "";
  let callInitiaterDetails = {
    callInitiaterTwoLetters,
    callInitiaterName,
    callInitiaterRole,
    callInitiaterHospital,
    callInitPicture,
  };
  let agoraCallsData = {
    appId: "",
    channelName: "",
    agoraToken: "",
    initiaterID: "",
    typeofCall: "",
    show: true,
    receiverName: "",
    callInitiaterDetails,
  };
  dispatch(showmodel(agoraCallsData));
  const callAPIRequestCancel = new AbortController();
  function abortAPICall() {
    callAPIRequestCancel.abort();
    window.removeEventListener("callWindowClose", abortAPICall);
  }
  window.addEventListener("callWindowClose", abortAPICall);
  getAgoraToken(channelDetails, callAPIRequestCancel.signal)
    .then((res) => {
      let data = res.data;
      if (data !== undefined && data !== null && data.status) {
        let url = `api/providerEndpoints/v1/sendMessage`;
        let params = {
          providerId: id,
          token: testToken,
          receiverId: receiver.id,
          message: channelName,
          type: typeofCall,
          patientId: patientid ? Number(patientid) : "",
          ...(receiver.roundWard && {
            roundWard: receiver?.roundWard,
            hospitalId: receiver?.hospitalId,
            wardName:
              receiver?.wardName === "All" ||
              receiver?.wardName === "Unassigned"
                ? ""
                : receiver?.wardName,
          }),
        };
        checkingProviderStatus(url, params, callAPIRequestCancel.signal)
          .then((response) => {
            let provStat = response.data;
            if (
              provStat.errorMessage ===
              "The selected clinician is not available for a call right now."
            ) {
              let providerStatus = {
                inActive: true,
                errMsg: provStat.errorMessage,
                name,
              };
              dispatch(providerAvailability(providerStatus));
              setTimeout(() => {
                dispatch(
                  providerAvailability({
                    ...providerStatus,
                    inActive: false,
                    errMsg: "",
                    name: "",
                  })
                );
                dispatch(showmodel({ ...agoraCallsData, show: false }));
                window.removeEventListener("callWindowClose", abortAPICall);
              }, 5000);
            } else {
              agoraCallsData = {
                appId:
                  /*JSON.stringify(LocalStorageGetValue("agoraAppId"))*/ state
                    ?.providerDetailsReducer?.agoraAppId,
                channelName,
                agoraToken: data.errorMessage,
                initiaterID: id,
                typeofCall,
                receiverId: receiver.id,
                show: true,
                auditId: provStat.auditId,
                twoLetters,
                hospital,
                profilePicUrl,
                roleCall,
                name,
                callInitiaterDetails,
              };
              window.removeEventListener("callWindowClose", abortAPICall);
              dispatch(showmodel(agoraCallsData));
            }
          })
          .catch((err) => {
            console.dir(err);
            let resetState = {
              appId: "",
              show: false,
              channelName: "",
              agoraToken: "",
              initiaterID: "",
              typeofCall: "",
              receiverName: "",
              collapse: false,
            };
            dispatch(showmodel(resetState));
            checkingProviderStatus(url, {
              providerId: id,
              token: testToken,
              receiverId: receiver.id,
              message: "CallerReject",
              type: "CallerReject",
              patientId: patientid ? Number(patientid) : "",
              ...(receiver.roundWard && {
                roundWard: receiver?.roundWard,
                hospitalId: receiver?.hospitalId,
                wardName:
                  receiver?.wardName === "All" ||
                  receiver?.wardName === "Unassigned"
                    ? ""
                    : receiver?.wardName,
              }),
            });
            window.removeEventListener("callWindowClose", abortAPICall);
            if (err.message === "canceled") {
              return;
            }
            toast.error("Network issue. Call failed.", {
              style: { background: "#E52727" },
            });
          });
      } else if (
        data !== undefined &&
        data !== null &&
        data.server === "server_issue"
      ) {
        let resetState = {
          appId: "",
          show: false,
          channelName: "",
          agoraToken: "",
          initiaterID: "",
          typeofCall: "",
          receiverName: "",
          collapse: false,
        };
        dispatch(showmodel(resetState));
        window.removeEventListener("callWindowClose", abortAPICall);
      }
    })
    .catch((err) => {
      console.dir(err, "ERROR");
      let resetState = {
        appId: "",
        show: false,
        channelName: "",
        agoraToken: "",
        initiaterID: "",
        typeofCall: "",
        receiverName: "",
        collapse: false,
      };
      dispatch(showmodel(resetState));
      window.removeEventListener("callWindowClose", abortAPICall);
      if (err.message === "canceled") {
        return;
      }
      toast.error("Network issue. Call failed.", {
        style: { background: "#E52727" },
      });
    });
}

function initiateGroupCallService(
  e,
  channelName,
  typeofCall,
  providerList,
  auditId,
  teamName,
  dispatch,
  reduxState
) {
  //let id = JSON.parse(LocalStorageGetValue("id"));
  const state = store.getState();
  let userDetails =
    /*JSON.parse(LocalStorageGetValue("userDetails"))*/ state
      ?.providerDetailsReducer?.provider;
  let id = state?.providerDetailsReducer?.provider?.id;
  let channelDetails = { channelName, uid: JSON.parse(id) };
  let callInitiaterFirst = userDetails["fname"];
  let callInitiaterLast = userDetails["lname"];
  let callInitPicture = userDetails["profilePicUrl"];
  let callInitiaterTwoLetters =
    callInitiaterFirst.replace(/[\s'.-]/g, "")[0].toUpperCase() +
    callInitiaterLast.replace(/[\s'.-]/g, "")[0].toUpperCase();
  let callInitiaterName =
    userDetails["name"].length > 10
      ? userDetails["name"].slice(0, 10) + "..."
      : userDetails["name"];
  let initRole =
    userDetails["role"] !== "BD" ? "," + userDetails["remoteProviderType"] : "";
  callInitiaterName = callInitiaterName + initRole;
  let callInitiaterRole =
    userDetails["remoteProviderType"] === undefined
      ? ""
      : userDetails["remoteProviderType"];
  //need to change after db cleanup
  /*let callInitiaterHospital =
    userDetails["hospital"] === "Remote Clinician" ||
    userDetails["hospital"] === "Remote Side Provider"
      ? "Remote Clinician"
      : "Bedside Clinician";*/
  let callInitiaterHospital =
    userDetails["role"] === "RD"
      ? "Remote Clinician"
      : userDetails["role"] === "BD"
      ? "Bedside Clinician"
      : "";
  let callInitiaterDetails = {
    callInitiaterTwoLetters,
    callInitiaterName,
    callInitiaterRole,
    callInitiaterHospital,
    callInitPicture,
  };
  let agoraCallsData = {
    appId: "",
    channelName: "",
    agoraToken: "",
    initiaterID: "",
    typeofCall: "",
    show: true,
    receiverName: "",
    callInitiaterDetails,
  };
  dispatch(showGroupModel(agoraCallsData));
  const callAPIRequestCancel = new AbortController();
  function abortAPICall() {
    callAPIRequestCancel.abort();
    window.removeEventListener("callWindowClose", abortAPICall);
  }
  window.addEventListener("callWindowClose", abortAPICall);
  getAgoraToken(channelDetails, callAPIRequestCancel.signal)
    .then((res) => {
      let data = res.data;
      agoraCallsData = {
        appId:
          /*JSON.stringify(LocalStorageGetValue("agoraAppId"))*/ state
            ?.providerDetailsReducer?.agoraAppId,
        channelName: String(channelName),
        agoraToken: data.errorMessage,
        initiaterID: id,
        typeofCall,
        show: true,
        auditId,
        patientId: Number(channelName),
        teamName,
        groupCallProviderData: providerList,
        callInitiaterDetails,
      };
      const {
        CallReducerShow: { show, groupCallShow },
      } = reduxState;
      if (show || groupCallShow) {
        toast.warn("Already in Call");
      } else {
        dispatch(showGroupModel(agoraCallsData));
      }
      window.removeEventListener("callWindowClose", abortAPICall);
    })
    .catch((err) => {
      let agoraCallsData = {
        appId: "",
        channelName: "",
        agoraToken: "",
        initiaterID: "",
        typeofCall: "",
        show: false,
        receiverName: "",
        collapse: false,
      };
      dispatch(showGroupModel(agoraCallsData));
      window.removeEventListener("callWindowClose", abortAPICall);
      if (err.message === "canceled") {
        groupCallDisconnect(
          String(auditId),
          String(channelName),
          Number(channelName)
        );
        return;
      }
      toast.error("Network issue. Call failed.", {
        style: { background: "#E52727" },
      });
    });
}

function completeFamilyCall(requestPayload) {
  return axios.post(
    "/api/providerEndpoints/v1/familyCallCompleted",
    requestPayload
  );
}

async function initiateThirdPartyCallService(message) {
  try {
    const {
      providerDetailsReducer: {
        agoraAppId: appid,
        provider: { id: providerId },
      },
    } = store.getState() || {};
    /*const providerToken = await getThirdPartyCallData(
      String(message),
      providerId
    );
    store.dispatch(
      openThirdPartyCall({
        channel: String(message),
        token: providerToken,
        appid,
      })
    );
    const randomNumber = Math.floor(Math.random() * 100);
    //const {channel:thirdPartyChannel,token:thirdPartyToken} = await getThirdPartyCallData(String(message),providerId)
    const thirdPartyToken = await getThirdPartyCallData(
      String(message),
      randomNumber
    );*/
    const randomNumber = Math.floor(Math.random() * 100);
    const searchParams = new URLSearchParams();
    searchParams.append("channel", "naveen");
    searchParams.append(
      "token",
      "007eJxTYFiwR8AvYiHvOrVvXpskP/X5yMtPfnRk8+HGP0UvZMMsd31XYDA3STU0SDVOTUs0MTdJSrSwTDRNSk4xMDeytEwxN0k29WnNSmsIZGT4fu0QKyMDBIL4bAx5iWWpqXkMDADmgSIm"
    );
    searchParams.append("appid", "74e10e3efa474ba89a5bcd07299d74c5");
    searchParams.append("uid", randomNumber);
    window.open(`/test/call?${searchParams.toString()}`);
  } catch (err) {}
}

export const commonservice = {
  filterfunction,
  initiateCallService,
  initiateGroupCallService,
  initiateThirdPartyCallService,
  completeFamilyCall,
};
