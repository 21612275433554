import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Toolbar,
  Typography,
  ListItem,
  ListItemIcon,
  ListItemText,
  List,
  Divider,
} from "@material-ui/core";
import logoutIcon from "../../Assets/images/Logout.png";
import feedbackIcon from "../../Assets/images/feedback.png";
import VitalsDashboardIcon from "../../Assets/images/vitalsDashboard.svg";
import { withTranslation } from "react-i18next";
import Chat from "../../Assets/images/Chat.png";
import { LocalStorageSetValue } from "../../Services/Auth";
import User from "../../Assets/images/Chart.png";
import { resetCall } from "../../Redux/Actions/callActions";
import { connect } from "react-redux";
import User2 from "../../Assets/images/SVGS/3 User 2.svg";
import Group883486 from "../../Assets/images/SVGS/Group 883486.svg";
import ConferenceIcon from "../../Assets/images/ConferenceIcon.svg";
import ConferenceOnlineIcon from "../../Assets/images/ConferenceOnlineIcon.svg";
import { version } from "../../../package.json";
import * as firebaseConfig from "../../firebase-config";
import ArchiveIcon from "../../Assets/images/archivedDetails/Archive.svg";
import { getZoomMeeting } from "../../Services/ZoomMeeting";
import { ToggleOnlineConference } from "../../Redux/Actions/checkZoomDetails";
const styles = (theme) => ({
  root: {
    display: "flex",
  },
  footerList: {
    position: "absolute",
    bottom: 0,
  },
  footerCloseList: {
    position: "absolute",
    bottom: 0,
  },
  listbuttonitem: {
    paddingLeft: "10px",
  },
  listtextitem: {
    //paddingLeft: "29px",
    left: 60,
    position: "absolute",
  },
  listiconitem: {
    minWidth: 0,
  },
  listiconitemclose: {
    minWidth: "100%",
  },
  button: {
    marginTop: 10,
    marginBottom: 10,
    textTransform: "inherit",
    color: "white",
    fontFamily: "PoppinsaSemiBold",
    borderRadius: 10,

    "&$selected": {
      backgroundColor: "#344563",
      color: "white",
    },
    "&$active": {
      backgroundColor: "#344563",
      color: "white",
    },
    "&$selected:hover": {
      backgroundColor: "#344563",
      color: "white",
    },
    "&:hover": {
      backgroundColor: "#344563",
    },
  },
});

class Sidebar extends Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedMenu: "",
      isDrawer: props.isDrawer,
      currentPath: sessionStorage.getItem("currentPath"),
    };
  }

  componentDidUpdate(prevState, currentState) {
    if (prevState.isDrawer !== this.props.isDrawer) {
      this.setState({ isDrawer: this.props.isDrawer });
    }
  }
  componentDidMount() {
    const { userId } =
      /*JSON.parse(LocalStorageGetValue("userDetails"))*/ this.props
        .providerData;
    getZoomMeeting({ id: userId })
      .then((data) => {
        const activeZoom = data.some(({ status }) => status === "Active");
        this.props.toggleZoomStatus(activeZoom);
      })
      .catch((err) => {});
  }
  redirect = (menu) => {
    if (this.props.location.pathname === menu.url) {
      return;
    }
    this.props.resetBreadCrumbState();
    LocalStorageSetValue("navigationPath", []);
    LocalStorageSetValue("currentPatient", "");
    this.setState({ selectedMenu: menu });
    if (menu.elementId === "signout") {
      this.props.resetCall();
      this.props.logout();
    } else {
      let { url } = menu;
      this.props.history.push(url);
      this.setState({ currentPath: url });
      sessionStorage.setItem("currentPath", url);
      window.history.replaceState(null, "", "/");
    }
  };

  render() {
    const { classes } = this.props;
    let { t } = this.props;
    const userDetails =
      /*JSON.parse(LocalStorageGetValue("userDetails"))*/ this.props
        .providerData;
    const { role } = userDetails;
    const routeData = [
      {
        key: 1,
        name: "My eConsults",
        url: "/dashboard",
        icon: <img alt="side" src={Group883486} />,
        ariaLabel: "Dashboard",
        elementId: "dashboard",
      },
      {
        key: 2,
        name: "Patient Census",
        url: "/patientCensus",
        icon: <img alt="side" src={User2} />,
        ariaLabel: "patientCensus",
        elementId: "patientCensus",
      },
      {
        key: 3,
        name: "Vitals Dashboard",
        url: "/vitalsDashboard",
        icon: <img alt="side" src={VitalsDashboardIcon} />,
        ariaLabel: "vitalsDashboard",
        elementId: "vitalsDashboard",
      },
      ...(role === "RD"
        ? [
            {
              key: 4,
              name: "Archived Patients",
              url: "/archiveHomepage",
              icon: <img alt="side" src={ArchiveIcon} />,
              ariaLabel: "patientArchive",
              elementId: "patientArchive",
            },
          ]
        : []),
    ];
    const footerData = [
      {
        key: 5,
        name: "Conference",
        url: "/conference",
        icon: this.props.zoomConference ? (
          <img
            src={ConferenceOnlineIcon}
            style={{ transform: "translateX(-9.5px)" }}
          />
        ) : (
          <img
            alt="DirectoryIcon"
            src={ConferenceIcon}
            style={{ transform: "scale(0.96) translateX(-1px)" }}
          />
        ),
        ariaLabel: "Conference",
        elementId: "Conference",
      },
      {
        key: 1,
        name: t("providerDirectory.label"),
        url: "/provider-directory",
        icon: <img alt="DirectoryIcon" src={User} />,
        ariaLabel: "Provider Directory",
        elementId: "providerDirectory",
      },
      {
        key: 2,
        name: t("support.label"),
        url: "/support",
        icon: <img alt="mailIcon" src={Chat} />,
        ariaLabel: "Contact support",
        elementId: "support",
      },
      {
        key: 3,
        name: t("feedback.label"),
        url: "/feedback",
        icon: <img alt="feedbackicon" src={feedbackIcon} />,
        ariaLabel: "feedback",
        elementId: "feedback",
      },
      {
        key: 4,
        name: t("signOut.label"),
        url: "/signout",
        icon: <img alt="logoutIcon" src={logoutIcon} />,
        ariaLabel: "sign out",
        elementId: "signout",
      },
    ];

    return (
      <>
        <Grid container spacing={0} style={{ height: "100%" }}>
          <Grid item xs={12} sm={12}>
            <List
              style={{ padding: "18px", position: "sticky", paddingBottom: 0 }}
            >
              {routeData.map((route, index) => (
                <ListItem
                  button
                  key={route.key}
                  className={classes.button}
                  classes={{ root: classes.listbuttonitem }}
                  style={{
                    backgroundColor:
                      route.url === this.props.currentUrl ? "#344563" : "",
                    fontFamily: "Poppins !important",
                    height: "44px",
                    borderRadius: "40px",
                    position: "relative",
                  }}
                  onClick={() => this.redirect(route)}
                  aria-label={route.ariaLabel}
                  id={route.elementId}
                >
                  <ListItemIcon classes={{ root: classes.listiconitem }}>
                    {route.icon}
                  </ListItemIcon>
                  <ListItemText
                    classes={{ root: classes.listtextitem }}
                    primary={
                      <Typography
                        className="content-emphasized-sidenav-text"
                        style={{
                          ...(this.props.sideBarClosed && {
                            visibility: "hidden",
                          }),
                        }}
                      >
                        {route.name}
                      </Typography>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            style={{ display: "flex", alignItems: "flex-end" }}
          >
            <List
              style={{
                padding: "18px",
                width: "100%",
                position: "sticky",
                paddingBottom: 0,
              }}
            >
              <Toolbar
                style={{
                  padding: 0,
                  minHeight: 10,
                }}
              >
                {this.state.isDrawer && (
                  <Divider style={{ background: "gray", width: "100%" }} />
                )}
              </Toolbar>
              {footerData.map((footer, index) => (
                <ListItem
                  button
                  key={footer.key}
                  aria-label={footer.ariaLabel}
                  id={footer.elementId}
                  className={classes.button}
                  classes={{ root: classes.listbuttonitem }}
                  style={{
                    backgroundColor:
                      footer.url === this.props.currentUrl ? "#344563" : "",
                    fontFamily: "Poppins !important",
                    position: "inherit",
                    height: "44px",
                    borderRadius: "40px",
                  }}
                  onClick={() => this.redirect(footer)}
                >
                  <ListItemIcon classes={{ root: classes.listiconitem }}>
                    {footer.icon}
                  </ListItemIcon>
                  <ListItemText
                    classes={{ root: classes.listtextitem }}
                    style={{ color: "white" }}
                    primary={
                      <Typography
                        className="content-emphasized-sidenav-text"
                        style={{
                          ...(this.props.sideBarClosed && {
                            visibility: "hidden",
                          }),
                        }}
                      >
                        {footer.name}
                      </Typography>
                    }
                  />
                </ListItem>
              ))}

              <ListItem
                style={{
                  display: "flex",
                  justifyContent: "center",
                  position: "inherit",
                }}
                aria-label="Copy Right"
                id="copyright"
              >
                <div style={{ textAlign: "center", height: "50px" }}>
                  <Typography style={{ color: "#5E6C84", fontSize: 12 }}>
                    {this.state.isDrawer
                      ? `Copyright ©${new Date().getFullYear()} Omnicure`
                      : " "}
                  </Typography>
                  <Typography style={{ color: "#5E6C84", fontSize: 12 }}>
                    {this.state.isDrawer ? "All Rights Reserved" : " "}
                  </Typography>
                  <Typography style={{ color: "#5E6C84", fontSize: 12 }}>
                    {this.state.isDrawer
                      ? "v" +
                        version.replace(
                          /(\d+)\.(\d+)\.(\d+)(-rc\.(\d))?/,
                          version.includes("-rc")
                            ? `$1.$2.$3(0.$5)`
                            : `$1.$2.$3`
                        ) +
                        ` ${firebaseConfig.firebaseConfig.projectId
                          ?.replace("omnicure", "")
                          ?.replace("-", "")
                          ?.toUpperCase()}`
                      : " "}
                  </Typography>
                </div>
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </>
    );
  }
}

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => {
  return {
    zoomConference: state?.ZoomReducer.zoomConference,
    sideBarClosed: !state?.LeftMenuPanel.leftPanelExpanded,
    providerData: state?.providerDetailsReducer?.provider,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    resetBreadCrumbState() {
      dispatch({ type: "RESETBREADCRUMB" });
    },
    resetCall: () => dispatch(resetCall()),
    toggleZoomStatus(payload) {
      dispatch(ToggleOnlineConference(payload));
    },
  };
};
export default withTranslation()(
  withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Sidebar))
);
