import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography, Avatar } from "@material-ui/core";
import Logo from "../../Assets/images/Color.png";
import { withTranslation } from "react-i18next";
import LogoText from "../../Assets/images/Group 1171276087.png";
import {
  secondarycolor6,
  secondarycolor25,
} from "../../Styles/Colors/colorCodes.js";

import LoginDialog from "../Login/LoginDialog";

const styles = (theme) => ({
  content: {
    color: secondarycolor25,
    background: "#EFF1F5 !important",
  },
  subcontent: {
    color: secondarycolor25,
    marginBottom: 15,
    background: secondarycolor6,
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
});

class LaunchLayout extends Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  render() {
    const { classes, children, t } = this.props;
    return (
      <div>
        <main className={classes.content}>
          <Grid
            container
            justify="center"
            alignItems="center"
            spacing={0}
            style={{
              minHeight:
                window.matchMedia("(hover: none) and (pointer: coarse)")
                  .matches &&
                (navigator.maxTouchPoints > 2 || navigator.msMaxTouchPoints > 2)
                  ? "-webkit-fill-available"
                  : "100vh",
            }}
          >
            <div
              style={{
                ...(this.props.unsupportedBrowser && { marginTop: 24 }),
              }}
            >
              <div
                style={{
                  marginBottom: "35px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div style={{ textAlign: "center" }}>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Avatar alt="Omnicure" src={Logo} />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      margin: "10px",
                    }}
                  >
                    <img
                      alt="Omnicure text"
                      src={LogoText}
                      style={{ width: "147px", height: "28px" }}
                    />
                  </div>
                  <Typography className="content-body-text">
                    {t("omnicureNote1.label")}
                  </Typography>
                  <Typography className="content-body-text">
                    {t("omnicureNote2.label")}
                  </Typography>
                </div>
              </div>
              {children}
            </div>
          </Grid>
        </main>
        <LoginDialog />
      </div>
    );
  }
}

LaunchLayout.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  unsupportedBrowser: state.unsupportedBrowserReducer.unsupportedBrowser,
});

export default withTranslation()(
  withStyles(styles)(connect(mapStateToProps)(LaunchLayout))
);
