import { useState, useEffect } from "react";
import { Dialog, Button } from "@material-ui/core";
import { useSelector, shallowEqual } from "react-redux";
import { commonservice } from "../../../providerDirectory/utils";
import { toast } from "react-toastify";
import LoaderDialog from "../../../Dashbboard/LoaderDialog";
import SuccessDialoag from "../../EconsultsDetails/SuccessDialoag";

function ThirdPartyAlertDialog() {
  const [thirdPartyDisablePopup, setThirdPartyDisablePopup] = useState(false);
  const [thirdPartyCallData, setThirdPartyCallData] = useState(null);
  const [loader, setLoader] = useState(false);
  const [successDialog, setSuccessDialog] = useState(false);
  const callFeedBackShow = useSelector(
    (state) => state.CallReducerShow.callFeedBackShow
  );
  const providerId = useSelector(
    (state) => state?.providerDetailsReducer?.provider?.id
  );
  const thirdPartyCallDetails = useSelector((state) => {
    const thirdPartyCallShow = state?.CallReducerShow?.thirdPartyCallShow;
    const auditId = state?.CallReducerShow?.thirdPartyAgoraCallsData?.auditId;
    const patientId =
      state?.CallReducerShow?.thirdPartyAgoraCallsData?.patientDetails
        ?.patientId;
    return { thirdPartyCallShow, auditId, patientId };
  }, shallowEqual);
  function handleClose() {
    setThirdPartyDisablePopup(false);
  }
  function handleDisable() {
    setLoader(true);
    commonservice
      .completeFamilyCall({
        ...thirdPartyCallData,
        providerId,
        callEnded: true,
      })
      .then(({ data: { status, errorMessage } }) => {
        if (status) {
          setSuccessDialog(true);
        } else {
          toast.warn(errorMessage);
        }
      })
      .finally(() => {
        handleClose();
        setLoader(false);
      });
  }
  useEffect(() => {
    if (callFeedBackShow && thirdPartyCallData) {
      return () => {
        setThirdPartyDisablePopup(true);
      };
    }
  }, [callFeedBackShow]);
  useEffect(() => {
    const { thirdPartyCallShow, auditId, patientId } = thirdPartyCallDetails;
    if (thirdPartyCallShow) {
      setThirdPartyCallData({ auditId, patientId });
    }
  }, [thirdPartyCallDetails]);
  useEffect(() => {
    if (!thirdPartyDisablePopup) {
      setThirdPartyCallData(null);
    }
  }, [thirdPartyDisablePopup]);
  return (
    <>
      <Dialog open={thirdPartyDisablePopup}>
        <div
          style={{
            width: 325,
            height: 165,
            display: "grid",
            placeItems: "center",
            gridTemplateRows: "repeat(3, 1fr)",
          }}
        >
          <div
            style={{
              color: "#172B4D",
              fontFamily: "PoppinsSemiBold",
              fontSize: "1.125rem",
              lineHeight: "24px",
            }}
          >
            Third Party Call
          </div>
          <div
            style={{
              color: "#172B4D",
              fontFamily: "PoppinsRegular",
              fontSize: "0.75rem",
              lineHeight: "16px",
              padding: "0 30px",
              textAlign: "center",
            }}
          >
            Disable Conference Link to the Family Member
          </div>
          <div
            style={{
              placeSelf: "stretch",
              display: "grid",
              gridTemplateColumns: "1fr auto 1fr",
              placeItems: "center",
              borderTop: "1px solid #b3bac5",
            }}
          >
            <Button
              disableRipple
              disableElevation
              style={{
                textTransform: "none",
                borderRadius: 8,
                fontFamily: "PoppinsSemiBold",
                fontSize: "0.875rem",
                color: "rgb(23, 43, 77)",
                height: 40,
              }}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <div style={{ width: 1, height: 55, background: "#b3bac5" }}></div>
            <Button
              variant="contained"
              disableRipple
              disableElevation
              style={{
                textTransform: "none",
                fontFamily: "Poppins",
                fontWeight: "bold",
                borderRadius: 8,
                color: "#FFF",
                fontSize: 14,
                background: "#9B51E0",
              }}
              onClick={handleDisable}
            >
              Disable
            </Button>
          </div>
        </div>
        <LoaderDialog loader={loader} zIndex={1301} />
      </Dialog>
      <SuccessDialoag
        open={successDialog}
        close={() => {
          setSuccessDialog(false);
        }}
        subText="Link Deleted Successfully"
      />
    </>
  );
}

export default ThirdPartyAlertDialog;
