import axios from "axios";
import { store } from "../../index";

export const triggerNotificationTeamMembers = async (
  receiverIds,
  id,
  token,
  type,
  patientsId,
  message
) => {
  try {
    const { data } = await axios.post(
      "/api/providerEndpoints/v1/sendMessageMultiple",
      { receiverIds, id, token, type, patientsId, message }
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const groupCallDisconnect = async (
  auditId,
  message,
  patientId,
  receiverId = 0
) => {
  const state = store.getState();
  const providerId =
    /*LocalStorageGetValue("id")*/ state?.providerDetailsReducer?.provider?.id;
  const token = /*String(LocalStorageGetValue("token"))*/ String(
    state?.providerDetailsReducer?.token
  );
  try {
    const { data } = await axios.post(
      "/api/providerEndpoints/v1/sendMessageNotification",
      {
        auditId,
        message,
        patientId,
        providerId,
        receiverId,
        token,
        type: "CallerReject",
      }
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const callConnectsaveCallAudit = async (auditId) => {
  const state = store.getState();
  const id = state?.providerDetailsReducer?.provider?.id;
  const providerId = String(/*LocalStorageGetValue("id")*/ id);
  try {
    const { data } = await axios.post(
      "/api/providerEndpoints/v1/saveAuditCall",
      { auditId, providerId, type: "", callStatus: true }
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const callDisconnectsaveCallAudit = async (auditId) => {
  const state = store.getState();
  const id = state?.providerDetailsReducer?.provider?.id;
  const providerId = String(/*LocalStorageGetValue("id")*/ id);
  try {
    const { data } = await axios.post(
      "/api/providerEndpoints/v1/saveAuditCall",
      { auditId, providerId, type: "CallerReject", callStatus: false }
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};
export const callDisconnectSOSsaveCallAudit = async (auditId) => {
  const state = store.getState();
  const id = state?.providerDetailsReducer?.provider?.id;
  const providerId = String(/*LocalStorageGetValue("id")*/ id);
  try {
    const { data } = await axios.post(
      "/api/providerEndpoints/v1/saveAuditCall",
      { auditId, providerId, type: "sos_dismiss", callStatus: false }
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const groupCallNotAnswered = async (
  auditId,
  message,
  patientId,
  receiverId
) => {
  const state = store.getState();
  const providerId =
    /*LocalStorageGetValue("id")*/ state?.providerDetailsReducer?.provider?.id;
  const providerToken = state?.providerDetailsReducer?.token;
  const token = /*String(LocalStorageGetValue("token"))*/ String(providerToken);
  try {
    const { data } = await axios.post(
      "/api/providerEndpoints/v1/sendMessageNotification",
      {
        auditId,
        message,
        patientId,
        providerId,
        receiverId,
        token,
        type: "CallerNotAnswer",
      }
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};
