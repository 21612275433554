import {
  Dialog,
  Paper,
  Grid,
  Typography,
  Toolbar,
  Button,
} from "@material-ui/core";
import Checkmark from "../../../Assets/images/Checkmark Illustration.png";
import LaunchComponentLable from "../../LaunchComponentLable";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import "../../../Styles/Buttons/Buttons.scss";

function SuccessDialoag({ open, close, t, subText }) {
  return (
    <Dialog
      id="success-dialog"
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <Paper className="dialog-paper">
        <Toolbar className="toolbar-center">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <img
                alt="Checkmark"
                width="100px"
                height="100px"
                src={Checkmark}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <LaunchComponentLable label={t("addpatient.success")} />
            </Grid>
            <Grid style={{ marginBottom: "44px" }} item xs={12} sm={12}>
              <Typography
                aria-label="success page text"
                id="subtext"
                className="content-form-bottom-label-text"
              >
                {" "}
                {subText}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Button
                aria-label="See patient"
                id="seepatient"
                fullWidth
                autoFocus
                onClick={() => close()}
                className="mockup-button"
              >
                {t("close.label")}
              </Button>
            </Grid>
            <Grid item xs={12} sm={12}></Grid>
          </Grid>
        </Toolbar>
      </Paper>
    </Dialog>
  );
}
SuccessDialoag.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withTranslation()(SuccessDialoag);
