export const firebaseConfig = {
    apiKey: "AIzaSyB0ciCKAxKu37vGUbl6gBNSb0fC5Ff44a0",
    authDomain: "dev-omnicure.firebaseapp.com",
    databaseURL: "https://dev-omnicure.firebaseio.com",
    projectId: "dev-omnicure",
    storageBucket: "dev-omnicure.appspot.com",
    messagingSenderId: "462063129427",
    appId: "1:462063129427:web:d1fb129ea379d30441c7a6",
    measurementId: "G-7CMWQ1D0HX"
  };
  